import React from "react";
import { InputLabelStyled } from "./styled";

const LabelText = ({ label, error, isRequired = false }) => {
  return (
    <InputLabelStyled>
      {label}
      {isRequired && <span>*</span>}
    </InputLabelStyled>
  );
};

export default LabelText;

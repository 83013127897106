import React, { useState, useEffect } from "react";
import { TypoGraphyText } from "./DiscoverCompanyStyled";
import { Link, Grid } from "@mui/material";
import defaultImageUrl from "../../assets/defaultImage.svg";

const modalData = {
  display: "flex",
  gap: "25px",
  marginBottom: "31px",
  cursor: "pointer",
  flexWrap: "initial",
};

export default function DiscoverImageLoad({ searchCompany, handleDetailApi }) {
  const { Company_Logo_URL, Company_Name, Company_ID, Website } = searchCompany;
  const [isValid, setIsValid] = useState(null);
  const checkImageValidity = () => {
    const img = new Image();
    img.src = Company_Logo_URL;

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };
  useEffect(() => {
    checkImageValidity();
  }, [Company_Logo_URL]);
  return (
    <Grid onClick={() => handleDetailApi(Company_ID)} sx={modalData} container>
      <Grid item>
        <img
          style={{
            width: 36,
            height: 35,
            borderRadius: "12px",
          }}
          src={isValid ? Company_Logo_URL : defaultImageUrl}
        />
      </Grid>
      <Grid item>
        <TypoGraphyText>{Company_Name} </TypoGraphyText>
        <Link
          href={`http://${Website}`}
          target="_blank"
          underline="none"
          sx={{
            fontWeight: 500,
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
            textDecoration: "underline",
            color: "#4764F6",
          }}
        >
          {Website}
        </Link>
      </Grid>
    </Grid>
  );
}

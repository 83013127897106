import React, {useState} from "react";
import {Button, FormControlLabel, Grid, Link, Typography,} from "@mui/material";
import LoginLeftPart from "../../components/LoginLeftPart";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {loginUserUrlEncoded} from "../../api/authApi";
import Checkbox from "@mui/material/Checkbox";
import "./style.css";
import LabelText from "../../components/Common/LabelText";
import TextFieldBox from "../../components/Common/TextFieldBox";
import {validateEmail, validatePassword} from "../../helper/validation";
import {useAuth} from "../../components/AuthProvider";

export const StyledTypography = styled(Typography)(() => ({
  color: "#316ded",
  justifyContent: "center",
  display: "flex",
  gap: 10,
  alignItems: "center",
  background: "#fafafd",
  marginBottom: "20px",
  "&.MuiTypography-root ": {
    padding: "2rem 0",
    fontWeight: 700,
  },
}));

const LoginTypography = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const ValidationTypography = styled(Typography)(() => ({
  color: "red",
  fontWeight: 600,
  fontSize: "16px",
}));

const Login = () => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { setToken, setRole, setUserFirstName } = useAuth();

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value)
    setValidationError(validateEmail(e.target.value))
  }

  const handlePasswordInputChange = (e) => {
    setPassword(e.target.value)
    setValidationError(validatePassword(e.target.value))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validationError)

    const params = new URLSearchParams();
    params.append('username', email.toLowerCase().trim());
    params.append('password', password);
    if (validationError === "" || (validationError === undefined)) {
      const res = await loginUserUrlEncoded(params);
      if (res) {
        if (res.status !== 200) {
          setValidationError(res?.data?.detail);
        }
        if (res.status === 200) {
          setToken(res?.data.access_token)
          setRole(res?.data.role)
          setUserFirstName(res.data.first_name)
          if (res?.data?.role === 1) {
            navigate("/admin/send-invite");
          } else navigate("/landing");
        } else navigate("/");
      }
    }
  };

  return (
    <div className="wrap_login_page">
      <div className="login_div">
        <div className="wrap_login_first_part">
          <Typography variant="mainText" mb={"11px"} component={"p"}>
            Welcome Back!
          </Typography>
          <Typography variant="mainSubText" component={"p"} mb={"11px"}>
            Please log in to access your account.
          </Typography>
          <Grid style={{minHeight: "60px"}}>
            <ValidationTypography>{validationError}</ValidationTypography>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid mb={"14px"}>
              <LabelText label={"Email address"} isRequired={true} />
            </Grid>
            <Grid sx={{ borderRadius: "6px" }} marginBottom={"23px"}>
              <TextFieldBox
                variant="outlined"
                type={"text"}
                placeholder={"Enter Email Address"}
                name={"username"}
                handleChange={handleEmailInputChange}
                fullWidth
              />
            </Grid>
            <Grid mb={"14px"}>
              <LabelText label={"Password"} isRequired={true} />
            </Grid>
            <Grid sx={{ borderRadius: "6px" }} marginBottom={"23px"}>
              <TextFieldBox
                variant="outlined"
                type={"password"}
                placeholder={"Enter Password"}
                name={"password"}
                handleChange={handlePasswordInputChange}
                fullWidth
              />
            </Grid>
            <div className="wrap_forgot_password">
              <div className="wrap-rember">
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked style={{ color: "#4A2CF5" }} />
                  }
                  label=" Remember Me"
                  style={{
                    fontStyle: "normal",
                    fontSize: "16px",
                    color: "#58595B",
                    fontWeight: 600,
                  }}
                />
              </div>
              <div>
                <Link
                  href="/password/forgot"
                  underline="none"
                  sx={{
                    color: "#4A2CF5",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            <Grid mt={"52px"}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="loginButton"
              >
                <LoginTypography>Login</LoginTypography>
              </Button>
            </Grid>
          </form>
        </div>
      </div>
      <LoginLeftPart />
    </div>
  );
};

export default Login;



const Defaults = {
  search: {
    locations: [],
    termsInclude: [],
    termsExclude: [],
    industryInclude: [],
    industryExclude: [],
    revenueLowerBound: 500000,
    revenueUpperBound: 10000000000,
    employeesLowerBound: 0,
    employeesUpperBound: 500000
  }
}

export default Defaults
import React, { useEffect } from "react";
import AdminSideBar from "./AdminSideBar/Sidebar";
import Header from "./Header";

const MainLayout = ({children}) => {
  const role = localStorage.getItem("role");
  return (
    <>
      {role === "1" ? (
        <AdminSideBar />
      ) : (
        <Header />
      )}
      {children}
    </>
  );
};

export default MainLayout;

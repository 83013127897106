import PropTypes from "prop-types";


export let SocioEconomicPropTypes = PropTypes.shape({
  hispanicOrLatino: PropTypes.bool,
  female: PropTypes.bool,
  veteran: PropTypes.bool,
  minority: PropTypes.bool,
  hubzone: PropTypes.bool,
  lowOrModerateIncome: PropTypes.bool,
  rural: PropTypes.bool,
})
import {Box, useMediaQuery, useTheme} from "@mui/material";

const CompanyDetailGrid = ({children}) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('cdLg'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('cdMd'));

  let gridTemplateColumns, gridTemplateAreas;

  if (matchesLg) {
    gridTemplateColumns = '1fr 1fr 1fr'
    gridTemplateAreas =
      `"location      classification similar"
       "descriptive   people         similar"
       "incorporation socioeconomic  lists"
       "franchise     socioeconomic  lists"
       "metrics       metrics        metrics"`
  } else if (matchesMd) {
    gridTemplateColumns = 'repeat(2, 1fr)'
    gridTemplateAreas =
      `"location      classification"
       "descriptive   similar"
       "people        similar"
       "incorporation socioeconomic"
       "franchise     lists"
       "metrics       metrics"`
  } else {
    gridTemplateColumns = '1fr'
    gridTemplateAreas = `"location"
       "classification"
       "descriptive" 
       "similar"
       "people"
       "incorporation"
       "socioeconomic"
       "franchise"
       "lists"
       "metrics"`
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: gridTemplateColumns,
        gap: 0,
        gridTemplateRows: 'auto',
        gridTemplateAreas: gridTemplateAreas,
      }}
    >
      {children}
    </Box>)
}

export default CompanyDetailGrid
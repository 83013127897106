import styled from "@emotion/styled";
import {Button, Dialog, DialogTitle, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import LabelText from "./Common/LabelText";
import TextFieldBox from "./Common/TextFieldBox";
import {getUserDetailApi, updateUserDetailApi} from "../api/userApi";
import {useAuth} from "./AuthProvider";
import {
  validateCompany, validateConfirmPassword,
  validateFirst,
  validateLast, validatePassword,
} from "../helper/validation";

const StyledGrid = styled(Grid)(({ theme }) => ({
  minWidth: "450px",
  minHeight: "250px",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "30px",
}));

function AccountModal(props) {
  const { onClose, open } = props;

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [companyName, setCompanyName] = useState();

  const [validationError, setValidationError] = useState("");

  const {token} = useAuth();

  useEffect(() => {
    // Load data when opening the modal
    if (open) {
      setValidationError("")
      getUserDetailApi(token).then( (res) => {
        if (res) {
          if (res?.status === 200) {
            setFirstName(res?.data?.first_name);
            setLastName(res?.data?.last_name);
            setEmail(res?.data?.email);
            setCompanyName(res?.data?.company);
          } else {

          }
        }
      })
    } else {
      // Do nothing; We don't want to load data when closing the dialog
    }
  }, [open]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = [
      validateFirst(firstName),
      validateLast(lastName),
      validateCompany(companyName),
    ].filter((e) => e !== "");

    if (errors.length > 0) {
      setValidationError(errors[0]);
    } else {
      setValidationError("")

      const userdata = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        company: companyName,
      };

      const res = await updateUserDetailApi(token, userdata);
      if (res) {
        if (res?.response?.status === 409) {
          setValidationError("No update occured; the profile information is unchanged")
        } else if (res.status === 200) {
          onClose()
          // alert("successfully updated."
        } else {
         console.log(res)
        }
      }
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
    setValidationError(validateFirst(e.target.value))
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
    setValidationError(validateLast(e.target.value))
  }

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value)
    setValidationError(validateCompany(e.target.value))
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ sx: { borderRadius: "8px" } }}>

      <DialogTitle id="simple-dialog-title">
        <Typography variant={"mainText"}>
          My Profile
        </Typography>
      </DialogTitle>

      <StyledGrid container spacing={2}>

        <Grid item xs={12} style={{minHeight: "50px"}}>
          <Typography variant={"formValidation"}>{validationError}</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid mb={"14px"}>
            <LabelText label={"First name"} isRequired={true}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"first_name_text_field"}
            required
            fullWidth
            placeholder={"First name"}
            name={"first_name"}
            value={firstName}
            handleChange={handleFirstNameChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid mb={"14px"}>
            <LabelText label={"Last name"} isRequired={true}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"last_name_text_field"}
            required
            fullWidth
            placeholder={"Last name"}
            name={"last_name"}
            value={lastName}
            handleChange={handleLastNameChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid mb={"14px"}>
            <LabelText label={"Email"} isRequired={false}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"email_text_field"}
            required
            fullWidth
            disabled
            placeholder={"Email"}
            name={"email"}
            value={email}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid mb={"14px"}>
            <LabelText label={"Company name"} isRequired={true}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"company_name_text_field"}
            required
            fullWidth
            placeholder={"Company name"}
            name={"company_name"}
            value={companyName}
            handleChange={handleCompanyNameChange}
          />
        </Grid>

        <Grid item xs={12} md={6} mt={"33px"}>
          <Grid>
            <Button
              variant="contained"
              fullWidth
              className="loginButton"
              onClick={handleSubmit}
            >
              <Typography variant={"buttonFace"}>Apply Changes</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} mt={"33px"}>
          <Grid>
            <Button
              variant="contained"
              fullWidth
              className="loginButton"
              onClick={onClose}
            >
              <Typography variant={"buttonFace"}>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>

      </StyledGrid>
    </Dialog>
  );
}

export default AccountModal;
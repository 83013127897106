
import PropTypes from "prop-types";

export let AddressPropTypes = PropTypes.shape({
  fullAddress: PropTypes.string.isRequired,
  fullStreet: PropTypes.string,
  streetNumber: PropTypes.string,
  streetName: PropTypes.string,
  streetType: PropTypes.string,
  routeId: PropTypes.string,
  postDirection: PropTypes.string,
  floor: PropTypes.string,
  buildingId: PropTypes.string,
  occupancy: PropTypes.string,
  city: PropTypes.string,
  region1: PropTypes.string,
  postalCode: PropTypes.string,
  countryId: PropTypes.string,
  isHq: PropTypes.bool,
  msa: PropTypes.string,
  county: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number,
  pointRFC7946: PropTypes.arrayOf(PropTypes.number)
})
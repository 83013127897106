import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Card, Typography, Tabs, Tab, Chip, Divider } from "@mui/material";
import { BoxWraper } from "./ModalSideBar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styled from "@emotion/styled";
import {
  DescriptionTypography,
  SizingKeyTypography,
  SizingLabelTypography,
  SubDescriptionTypography,
  KeyTypographyStyled,
  MainTitle,
  SubMainTitle,
  PrimaryTextGrid,
  PrimarySubTextGrid,
  ConferenceStyleGrid
} from "./CompanyStyled";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={"30px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ModalSection({ companyDetail,companyDetailsOpen }) {
  const [value, setValue] = useState(0);
  const [isReadMore, setIsReadMore] = useState(true);


  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ImageGrid = styled(Grid)`
    width: 48px;
    height: 48px;
    background-color: #e1eaff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const overviewSizing = [
    {
      image: "/Image/employee.svg",
      label: "Total Employees",
      key: "Employees",
    },
    {
      image: "/Image/revenue.svg",
      label: "Total Revenue",
      key: "Revenue",
    },
    {
      image: "/Image/visit.svg",
      label: "Website Visits",
      key: "Visits",
    },
  ];

  const financialSizing = [
    {
      image: "/Image/revenue.svg",
      label: "Revenue Estimate",
      key: "Revenue",
      subLabel: "Medium Confidence",
    },
    {
      image: "/Image/revenue.svg",
      label: "Estimated Revenue Per Employee",
      key: "Revenue",
      subLabel: "Medium Confidence",
    },
  ];

  const Keywords = [
    "commercial snow",
    "seasonal color",
    "weed control",
    "fertilization",
    "tree care",
    "pest control",
    "irrigation",
    "lawn care",
    "shrubs",
    "hardscape",
  ];

  const executiveDetails = [
    {
      label: "Location",
      key: "Country",
    },
    {
      label: "Department",
      key: "Department",
    },
    {
      label: "Age",
      key: "Age", 
    },
    {
      label: "Education",
      key: "Education",
    },
  ];

  const tabList = [
    "Overview",
    "Financials",
    "Executives",
    "Conferences",
    "Feed",
  ];

  return (
    <Grid sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="custom-tab"
        >
          {tabList?.map((item, index) => (
            <Tab
              label={item}
              {...a11yProps(index)}
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "23px",
                textTransform: "initial",
              }}
            />
          ))}

          {/* <Tab label="Overview" {...a11yProps(0)} style={{}} />
          <Tab label="Financials" {...a11yProps(1)} />
          <Tab label="Executives" {...a11yProps(2)} />
          <Tab label="Conferences" {...a11yProps(2)} />
          <Tab label="Feed" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container gap={"30px"}>
          <Grid item>
            <SubDescriptionTypography>
              {companyDetail?.Description}
            </SubDescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container flexDirection={"column"} gap={"27px"}>
              <Grid item>
                <DescriptionTypography>Sizing</DescriptionTypography>
              </Grid>
              <Grid item>
                <Grid container gap={"35px"}>
                  {overviewSizing?.map((item,index) => {
                    const { image, key, label } = item;
                    return (
                      <Grid
                        item
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          minWidth: "200px",
                          padding: "19px 14px 21px 14px",
                          borderRadius: "8px",
                        }}
                      >
                        <Grid container gap={"13px"}>
                          <ImageGrid item>
                            <img
                              src={image}
                              width="24px"
                              height="24px"
                              alt="Internet Down"
                            />
                          </ImageGrid>
                          <Grid item>
                            <Grid
                              container
                              flexDirection={"column"}
                              gap={"10px"}
                            >
                              <Grid item>
                                <SizingLabelTypography>
                                {index === 1 && !companyDetailsOpen ? `${companyDetail?.[key] || key}` : companyDetail?.[key] || key}
                                </SizingLabelTypography>
                              </Grid>
                              <Grid item>
                                <SizingKeyTypography>
                                  {label}
                                </SizingKeyTypography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "30px" }} />
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight={600}>
              Description
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {isReadMore
                ? `${companyDetail?.Description?.slice(0, 250) + "..."}`
                : companyDetail?.Description}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#00a3d0", mt: 3 }}
              onClick={toggleReadMore}
            >
              {isReadMore
                ? companyDetail?.Description?.length > 80
                  ? "View Full Description"
                  : ""
                : " Show Less"}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight={600}>
              Sizing
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <span>Employees</span>
              <Typography variant="h5" fontWeight={600}>
                {companyDetail?.Employees}
              </Typography>
            </Box>
            <Box>
              <span>Revenue</span>
              <Typography variant="h5" fontWeight={600}>
                $ {companyDetail?.Revenue}
              </Typography>
            </Box>
            <Box>
              <span>Website Visits</span>
              <Typography variant="h5" fontWeight={600}>
                15.1K
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container flexDirection={"column"} gap={"27px"}>
          <Grid item>
            <DescriptionTypography>Sizing</DescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container gap={"35px"}>
              {financialSizing?.map((item,index) => {
                const { image, key, label } = item;
                return (
                  <Grid
                    item
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      minWidth: "200px",
                      padding: "19px 14px 21px 14px",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid container gap={"13px"}>
                      <ImageGrid item>
                        <img
                          src={image}
                          width="24px"
                          height="24px"
                          alt="Internet Down"
                        />
                      </ImageGrid>
                      <Grid item>
                        <Grid container flexDirection={"column"} gap={"10px"}>
                          <Grid item>
                            <SizingLabelTypography>
                            { !companyDetailsOpen ? `${companyDetail?.[key] || key}K/yr` : `${companyDetail?.[key] || key}K/yr`}
                            </SizingLabelTypography>
                          </Grid>
                          <Grid item>
                            <SizingKeyTypography>{label}</SizingKeyTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: "30px 0px" }} />
        <Grid container gap={"30px"} flexDirection={"column"}>
          <Grid item>
            <DescriptionTypography>Keywords</DescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container gap={"10px"}>
              {companyDetail?.Keywords?.map((item) => {
                return (
                  <Grid
                    item
                    style={{
                      background: "rgba(88, 89, 91, 0.1)",
                      borderRadius: "60px",
                      padding: "12px 23px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <KeyTypographyStyled>{item}</KeyTypographyStyled>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight={600}>
              Revenue
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mb: 4 }}>
              <span>Revenue Estimate</span>
              <Typography variant="h5" fontWeight={600}>
                $ {companyDetail.Revenue}
                <span
                  style={{
                    color: "#909497",
                    fontSize: "20px",
                    paddingLeft: "5px",
                  }}
                >
                  / yr <span>Medium Confidence</span>
                </span>
              </Typography>
            </Box>
            <Box>
              <span>Estimated Revenue Per Employee</span>
              <Typography variant="h5" fontWeight={600}>
                $ 69K{" "}
                <span
                  style={{
                    color: "#909497",
                    fontSize: "20px",
                    paddingLeft: "5px",
                  }}
                >
                  / yr
                </span>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid
          container
          flexDirection={"column"}
          style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "19px 0 18px 20px",
          }}
        >
          <Grid item>
            <Grid container gap={"13px"}>
              <ImageGrid item>
                <img
                  src="/Image/profile-circle.svg"
                  width={"24px"}
                  height={"24px"}
                  alt="alt"
                />
              </ImageGrid>
              <Grid item>
                <Grid container flexDirection={"column"}>
                  <MainTitle item>Don Manhard</MainTitle>
                  <SubMainTitle item>President,CEO, President/CEO</SubMainTitle>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "22px", marginBottom: "18px" }} />
          <Grid item>
            <Grid container gap={"60px"}>
              {executiveDetails?.map((item) => {
                const { label, key } = item;
                return (
                  <Grid item>
                    <Grid container flexDirection={"column"} gap={"8px"}>
                      <PrimaryTextGrid item>{label}</PrimaryTextGrid>
                      <PrimarySubTextGrid item>
                        {companyDetail?.[key] || key}
                      </PrimarySubTextGrid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
        <Grid container gap={"30px"} flexDirection={"column"}>
          <Grid item>
            <DescriptionTypography>Keywords</DescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container gap={"10px"}>
              {companyDetail?.Keywords?.map((item) => {
                return (
                  <Grid
                    item
                    style={{
                      background: "rgba(88, 89, 91, 0.1)",
                      borderRadius: "60px",
                      padding: "12px 23px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <KeyTypographyStyled>{item}</KeyTypographyStyled>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {/* <Card variant="outlined">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid>
                <AccountCircleIcon sx={{ color: "#909497" }} />
                <Typography fontWeight={600}>Don Manhard</Typography>
                <span>President,CEO, President/CEO</span>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <BoxWraper>
                <p>Location</p>
                <p>{companyDetail.Country}</p>
              </BoxWraper>
              <BoxWraper>
                <p>Department</p>
                <p>Executive</p>
              </BoxWraper>
              <BoxWraper>
                <p>Age</p>
                <p>60</p>
              </BoxWraper>
              <BoxWraper>
                <p>Education</p>
                <p style={{ width: "421px" }}>
                  University of Illinois at Urbana-Champaign 1978-1982Bachelor
                  of Applied Science (B.A.Sc.), Civil Engineering, Undergraduate
                </p>
              </BoxWraper>
            </AccordionDetails>
          </Accordion>
        </Card> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap= {"8px"}
        >
          <ImageGrid item>
            <img
              src="/Image/calendar.svg"
              width={"24px"}
              height={"24px"}
              alt="alt"
            />
          </ImageGrid>
          <Grid item>
            <ConferenceStyleGrid textAlign={"center"}>
              No Conferences or trade shows found{" "}
            </ConferenceStyleGrid>
            <ConferenceStyleGrid textAlign={"center"}>for this company.</ConferenceStyleGrid>
          </Grid>
        </Grid>
        <Divider style={{ margin: "30px 0" }} />
        <Grid container gap={"30px"} flexDirection={"column"}>
          <Grid item>
            <DescriptionTypography>Keywords</DescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container gap={"10px"}>
              {companyDetail?.Keywords?.map((item) => {
                return (
                  <Grid
                    item
                    style={{
                      background: "rgba(88, 89, 91, 0.1)",
                      borderRadius: "60px",
                      padding: "12px 23px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <KeyTypographyStyled>{item}</KeyTypographyStyled>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        {/* <Typography
          variant="h2"
          fontWeight={700}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {companyDetail?.Employees}
          <Chip
            label="Search size"
            sx={{ bgcolor: "#00a3d0", color: "#fff" }}
          />
        </Typography>
        <Typography fontWeight={600}>Total Employee</Typography> */}
      </TabPanel>
    </Grid>
  );
}

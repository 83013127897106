import axios from "axios";
import {createUrlParams} from "../helper/util";
import {authenticated_api} from "./base";

// TODO: Get rid of this
export const elasticSearchApi = async (payload) => {
  return undefined
};

export const searchApi = async (token, searchSpec) => {
  try {
    if (token) {
      return await authenticated_api.post("/search/", searchSpec,
        {headers: {Authorization: `Bearer ${token}`}}
      );
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchApiResultCount = async (token, searchSpec) => {
  try {
    if (token) {
      return await authenticated_api.post("/search-result-count/", searchSpec,
        {headers: {Authorization: `Bearer ${token}`}}
      );
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompanyDetails = async (payload) => {
  const token = localStorage.getItem("authToken");

  try {
    if (token) {
      return await authenticated_api.get(
        `/company_details/?${createUrlParams(payload)}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompanyViews = async (payload) => {
  const token = localStorage.getItem("authToken");

  try {
    if (token) {
      return await authenticated_api.get(
        `/companies_viewed/?${createUrlParams(payload)}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDiscoverCompany = async (payload) => {
  const token = localStorage.getItem("authToken");
  try {
    if (token) {
      return await authenticated_api.get(
        `/companies/?${createUrlParams(payload)}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLocationApi = async (token) => {
  // TODO: API supports dynamic filtering but not sure if practical yet.
  try {
    if (token) {
      return await authenticated_api.get(
        `/extent/locations/`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getSliderValue = async (token) => {
  // TODO: API supports dynamic filtering but not sure if practical yet.
  const query = {}
  try {
    if (token) {
      return await authenticated_api.post(
        `/extent/revenue_employee/`,
        query,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOperationModel = async (token) => {
  // TODO: API supports dynamic filtering but not sure if practical yet.
  try {
    if (token) {
      return await authenticated_api.get(
        `/extent/industry/`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getRecentView = async (token) => {
  return undefined
};

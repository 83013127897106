
export function createUrlParams(urlData) {
  let datasize = Object.keys(urlData)?.length;
  const keys = Object.keys(urlData);
  let search = "";
  if (datasize) {
    keys.forEach((key) => {
        search += `${key}=${urlData[key]}&`;
    });
    return search?.substring(0, search.length - 1);
  }
}

export const randomHexString = size => {
  let result = [];
  let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return result.join('');
}
import {ButtonGroup, Divider, Grid, Skeleton, TablePagination, Tooltip, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import ListCard from "./ListCard/ListCard";
import MainLayout from "../../components/layouts/MainLayout";
import SubHeader from "../../components/layouts/SubHeader";
import {useAuth} from "../../components/AuthProvider";
import {searchApi, searchApiResultCount} from "../../api/searchApi";
import FilterSection from "./FilterSection";
import Defaults from "../../helper/defaults";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DownloadIcon from '@mui/icons-material/Download';
import {PlaylistAdd} from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import { mkConfig, generateCsv, download } from "export-to-csv";


const Wrapper = styled(Grid)(({ theme }) => ({
  padding: "102px 0px 0px 0px",
  //  background: "#F2F3F4",
  "@media (max-width: 900px)": {
    // Styles for screens with a maximum width of 768px
    padding: "66px 0px 0px 0px",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "22px",
  color: "#000000"
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  border: "1px solid rgba(88,89,91,0.2)",
  marginBottom: "25px",
}));

const SkeletonStyle = styled(Skeleton)(({ theme }) => ({
  marginBottom: "28px",
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "10px",
  padding: "26px 98px 26px 25px",
  cursor: "pointer",
  maxWidth: "1257px",
  width: "100%",
  height: "160px",
}));

const StyledGroupButton = styled(Button)(({ theme }) => ({
  border: '1.5px solid rgba(88, 89, 91, 0.2)',
  color: "#000000",
    '&:hover': {
      border: `1.5px solid ${theme.palette.tertiary.main}`,
      color: theme.palette.tertiary.main,
      backgroundColor: theme.palette.secondary.main
  }
}));

function Item(props) {
  return null;
}

Item.propTypes = {
  sx: PropTypes.shape({gridArea: PropTypes.string}),
  children: PropTypes.node
};

export default function CompanyList () {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [formattedTotalRows, setFormattedTotalRows] = useState("")

  const [locations, setLocations] = useState(Defaults.search.locations)
  const [termsInclude, setTermsInclude] = useState(Defaults.search.termsInclude)
  const [termsExclude, setTermsExclude] = useState(Defaults.search.termsExclude)
  const [industryInclude, setIndustryInclude] = useState(Defaults.search.industryInclude)
  const [industryExclude, setIndustryExclude] = useState(Defaults.search.industryExclude)
  const [revenueLowerBound, setRevenueLowerBound] = useState(Defaults.search.revenueLowerBound)
  const [revenueUpperBound, setRevenueUpperBound] = useState(Defaults.search.revenueUpperBound)
  const [employeesLowerBound, setEmployeesLowerBound] = useState(Defaults.search.employeesLowerBound)
  const [employeesUpperBound, setEmployeesUpperBound] = useState(Defaults.search.employeesUpperBound)

  const [isKeywordOnlySearch, setIsKeywordOnlySearch] = useState(false)

  const { token } = useAuth();

  const {searchKey} = useParams();

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: "alphasearch-export"
  });

  const LargeNumberFormatter = Intl.NumberFormat("en", {
    maximumFractionDigits: 1,
    notation: "compact"
  })

  const formatResults = (resultCount) => {
    if (resultCount === 1001) {
      return ("lots of matches");
    } else {
      return (`at least ${LargeNumberFormatter.format(resultCount)} companies that match`);
    }
  };

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to} of ${formatResults(count)}`;
  }

  // This is just a first step in loading saved searches or external searches onto this page.
  useEffect( () => {
    if (searchKey !== undefined) {
      const spec = JSON.parse(sessionStorage.getItem(searchKey));
      if (spec.query.TermsInclude !== undefined) {
        setTermsInclude(spec.query.TermsInclude)
        setIsKeywordOnlySearch(true)
      }
    }
  }, []);

  const encodeSearchSpec = () => {
    return {
      query: {
        TermsInclude: termsInclude,
        TermsExclude: termsExclude,
        IndustryInclude: industryInclude,
        IndustryExclude: industryExclude,
        Location: locations,
        RevenueLowerBound: revenueLowerBound,
        RevenueUpperBound: revenueUpperBound,
        EmployeesLowerBound: employeesLowerBound,
        EmployeesUpperBound: employeesUpperBound
      },
      pagination: {
        page_size: rowsPerPage,
        page_num: page
      }
    }
  }

  useEffect( () => {
    const spec = encodeSearchSpec();

    searchApi(token, spec)
      .then((response) => {
        setCompanies(response.data);
      });

    searchApiResultCount(token, spec)
      .then((response) => {
        setTotalRows(response.data);
      }).then((response) => {

      });
    }, [page, rowsPerPage, termsInclude, termsExclude, revenueLowerBound, revenueUpperBound,
    employeesLowerBound, employeesUpperBound, industryInclude, industryExclude, locations]
  );

  const handlePageChange = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect( () => {
    setFormattedTotalRows(formatResults(totalRows))
  }, [totalRows])

  const exportToCSV = () => {
    let spec = encodeSearchSpec();
    spec = {...spec, ...{pagination: {page_size: 100, page_num: 0}}}

    searchApi(token, spec)
      .then((response) => {
        const data = response.data;
        const filtered_props = data.map((props) => {
          return({
            "id": props.stagedId,
            "name": props.primaryName,
            "address": props.primaryLocation?.fullAddress,
            "county": props.primaryLocation?.county,
            "msa": props.primaryLocation?.msa,
            "des0": props.descriptive?.[0],
            "des1": props.descriptive?.[1],
            "des2": props.descriptive?.[2],
            "kw1": props.keywords?.[0],
            "kw2": props.keywords?.[1],
            "kw3": props.keywords?.[2],
            "web": props.primaryWebsite,
            "phone": props.primaryTelephone,
            "headcountLow": props.harmonizedHeadcount.lowerBound,
            "headcountPointEst": props.harmonizedHeadcount.pointEstimate,
            "headcountHigh": props.harmonizedHeadcount.upperBound,
            "headcountObs": props.fteHeadcount.dataPoints.length + props.totalHeadcount.dataPoints.length + props.onlineHeadcount.dataPoints.length,
            "revenueLow": props.revenue.lowerBound,
            "revenuePointEst": props.revenue.pointEstimate,
            "revenueHigh": props.revenue.upperBound,
            "revenueObs": props.revenue.dataPoints.length,
            "revenuePerEmpLow": props.revenuePerEmployee.lowerBound,
            "revenuePerEmpPointEst": props.revenuePerEmployee.pointEstimate,
            "revenuePerEmpHigh": props.revenuePerEmployee.upperBound,
            "score": props.qualityScore,
            "forProfit": props.incorporation?.forProfit,
            "legalForm": props.incorporation?.legalForm,
            "marketability": props.incorporation?.marketability,
            "facebook": props.social?.facebookUrl,
            "linkedin": props.social?.linkedinUrl,
            "twitter": props.social?.twitterUrl,
            "hubzone": props.socioeconomic?.hubzone,
            "female": props.socioeconomic?.female,
            "hispanicOrLatino": props.socioeconomic?.hispanicOrLatino,
            "lowOrModerateIncome": props.socioeconomic?.lowOrModerateIncome,
            "minority": props.socioeconomic?.minority,
            "rural": props.socioeconomic?.rural,
            "veteran": props.socioeconomic?.veteran
          })

        })
        download(csvConfig)(generateCsv(csvConfig)(filtered_props));
      });
  };

  return (
    <MainLayout>
      <Wrapper>
        <SubHeader />
        <Grid
          sx={{
            padding: "43px 20px 0px 40px",
            "@media (max-width: 900px)": {
              padding: "0px 15px",
            },
          }}
          container
          gap={"35px"}
        >
          <Grid
            sx={{
              padding: "43px 20px 0px 40px",
              "@media (max-width: 900px)": {
                padding: "0px",
              },
            }}
            container
            gap={"35px"}
          >
            {isKeywordOnlySearch ? (
              <></>
              ) : (
              <FilterSection
                locations={locations}
                setLocations={setLocations}
                termsInclude={termsInclude}
                setTermsInclude={setTermsInclude}
                termsExclude={termsExclude}
                setTermsExclude={setTermsExclude}
                industryInclude={industryInclude}
                setIndustryInclude={setIndustryInclude}
                industryExclude={industryExclude}
                setIndustryExclude={setIndustryExclude}
                revenueLowerBound={revenueLowerBound}
                setRevenueLowerBound={setRevenueLowerBound}
                revenueUpperBound={revenueUpperBound}
                setRevenueUpperBound={setRevenueUpperBound}
                employeesLowerBound={employeesLowerBound}
                setEmployeesLowerBound={setEmployeesLowerBound}
                employeesUpperBound={employeesUpperBound}
                setEmployeesUpperBound={setEmployeesUpperBound}
                disabled={isKeywordOnlySearch}
                onClear={() => setPage(0)}
              />
            )}
            <Grid item flex={"1"}>
              {(
                <>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: "23px"}}>
                      <StyledTypography>
                        Search result: {formattedTotalRows}
                      </StyledTypography>
                      <ButtonGroup variant="outlined" sx={{
                        border: 'none'
                      }}>
                        <Tooltip
                          title={<Typography variant={"toolTipText"} >Download up to 100{<br/>}results as .csv</Typography>}
                          placement={"left-start"}
                          arrow
                        >
                          <StyledGroupButton onClick={exportToCSV}>
                            <DownloadIcon/>
                          </StyledGroupButton>
                        </Tooltip>
                        <StyledGroupButton disabled={true}><PlaylistAdd/></StyledGroupButton>
                        <StyledGroupButton disabled={true}><SaveIcon/></StyledGroupButton>
                      </ButtonGroup>
                  </Box>

                  <StyledDivider/>
                  <Grid>
                    {companies?.map((company) => (
                      <ListCard key={company.stagedId} loading={loading} company={company}/>
                    ))}
                    {totalRows && <TablePagination
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      component=""
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      count={totalRows}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                    />}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </MainLayout>
  );
};
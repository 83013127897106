import React from 'react'
import AlphaSearch from "../../assets/ALphaSearch.svg";
import ListDesign from "../../assets/Listing-Design.svg";
import GroupImg from "../../assets/GroupImg.svg";
import { Typography, Grid } from "@mui/material";
import styled from "@emotion/styled";


const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    fontSize: "46px",
    color: "#ffff",
    textAlign: "center",
    marginTop: "20px",
    marginBottom:"48px",
    lineHeight: "58px",
    textAlign: "center"
}));

const ItemGrid = styled(Grid)`
  &::before {
    content: "";
    position: absolute;
    background: url(/Image/login-curve.png) no-repeat 0 0;
    width: 100%;
    top: 0px;
    height: 78px;
    left: -90px;
    background-size: contain;
  }
  ,
  &::after {
    content: "";
    position: absolute;
    background: url("/Image/LoopSecond.svg");
    right: 0px;
    width: 128px;
    top:10px;
    height: 157px;
opacity: 0.2;
  }
`;

function LoginLeftPart() {
    return (
        <div
            style={{ position: "relative" }}
            className="wrap_alpha_serach">
            <ItemGrid>
                <div>
                    <img className="alpha_search" src={AlphaSearch} />
                    <StyledTypography
                        variant="h6"
                    >
                        Using Data to bring{" "}
                        <span style={{ color: "#FAFF14" }}>transparency</span>
                        <br></br> to the private markets.
                    </StyledTypography>
                    <img className="list_design_image" src= "/Image/login-img.png" />
                </div>
                {/* <div>
                    <img className="group_img" src={GroupImg} />
                </div> */}
            </ItemGrid>
        </div>
    )
}

export default LoginLeftPart;
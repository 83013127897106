import {Box, Button, Card, Grid, Snackbar, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ErrorMessage} from "../SignupPage";
import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {doesCompanyIdExist, getRecentlyViewedCompanies} from "../../api/companyApi";
import {useAuth} from "../../components/AuthProvider";
import RecentlyViewedCompanyCards from "../../components/RecentlyViewedCompanyCards";



const StyledTextField = styled(TextField)(({ theme }) => ({
  border: "1px solid  rgb(88, 89, 91,0.7)",
  borderRadius: "6px",
  width: "100%"
}));

const LoginTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const CancelBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#red",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const NoCompanySpecified = () => {
  const [isCompanyLookupButtonDisabled, setIsCompanyLookupButtonDisabled] = useState(true);
  const [companyIdToLookup, setCompanyIdToLookup] = useState(null);
  const [recentlyViewedIds, setRecentlyViewedIds] = useState([])
  const {token} = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    async function processCompanyLookupButtonState() {
      if (companyIdToLookup && companyIdToLookup.length > 0) {
        doesCompanyIdExist(token, companyIdToLookup).then((idExistsResponse) => {
            if (idExistsResponse.data === true) {
              setIsCompanyLookupButtonDisabled(false)
            } else if (idExistsResponse.data === false) {
              setIsCompanyLookupButtonDisabled(true)
            } else {
              setIsCompanyLookupButtonDisabled(true)
            }
          }
        )
      } else {
        setIsCompanyLookupButtonDisabled(true)
      }
    }
    processCompanyLookupButtonState();
  }, [companyIdToLookup]);


  useEffect(() => {
    getRecentlyViewedCompanies(token)
      .then(r => {
        if (r.status === 200) {
          setRecentlyViewedIds(r.data)
        } else {
          console.log(r)
          setRecentlyViewedIds([])
        }
      })
  }, [])

  function handleLookupCompany() {
    navigate(`/company/${companyIdToLookup}`)
  }

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('cdLg'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('cdMd'));

  let gridTemplateColumns, gridTemplateAreas;

  if (matchesLg) {
    gridTemplateColumns = 'repeat(5, 1fr)'
    gridTemplateAreas =
      `". idLookup recentlyViewed recentlyViewed ."
       ". .        recentlyViewed recentlyViewed ."`
  } else if (matchesMd) {
    gridTemplateColumns = 'repeat(2, 1fr)'
    gridTemplateAreas =
      `"idLookup recentlyViewed"
       ".        recentlyViewed"`
  } else {
    gridTemplateColumns = '1fr'
    gridTemplateAreas =
      `"idLookup"
       "recentlyViewed"`
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: gridTemplateColumns,
        gridTemplateAreas: gridTemplateAreas
      }}
    >
      <Box sx={{gridArea: "idLookup"}}>
        <Card variant={"outlined"} sx={{p: "40px", width: "500px", borderRadius: "16px"}}>
          <Typography variant="h2" mb={"11px"} component={"p"}>
            Get Company Details
          </Typography>
          <form onSubmit={handleLookupCompany}>
            <Box sx={{display: "flex", flexDirection: "column", p: 2, m: 2}}>
              <Box>
                <Typography variant="h6" component={"p"} mb={"14px"}>
                  Enter a Valid Company ID
                </Typography>
                <StyledTextField
                  onChange={(event) => setCompanyIdToLookup(event.target.value)}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{borderRadius: "6px", paddingBlock: "22px", marginTop: "30px"}}
                disabled={isCompanyLookupButtonDisabled}
              >
                <LoginTypography>Lookup</LoginTypography>
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
      <Box sx={{gridArea: "recentlyViewed"}}>
        <Card variant={"outlined"} sx={{p: "40px", maxWidth:"582px", borderRadius: "16px"}}>
          <Typography variant="h2" mb={"11px"} component={"p"}>
            Recently Viewed Companies
          </Typography>
          <Box sx={{pt: "10px"}}>
            <RecentlyViewedCompanyCards companyIds={recentlyViewedIds}/>
          </Box>
        </Card>
      </Box>
    </Box>
)
}

export default NoCompanySpecified
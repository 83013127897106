import PropTypes from "prop-types";

export let PersonPropTypes = PropTypes.shape({
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  credential: PropTypes.string,
  title: PropTypes.string,
  bio: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  imageUrl: PropTypes.string
})
import {useAuth} from "../AuthProvider";
import React, {useEffect, useState} from "react";
import {getCompanyDetailNew} from "../../api/companyApi";
import {labelForLocation} from "../../helper/address";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import {Grid, Typography} from "@mui/material";


const CompanyMenuItem = (props) => {
  const {id} = props
  const {token} = useAuth();
  const [companyData, setCompanyData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCompanyDetailNew(token, id)
      setCompanyData(response.data);
    };
    fetchData();
  }, [])

  const nameToUse = companyData?.primaryName ? companyData.primaryName : "(Unknown Name)"
  const subtitle = (companyData?.primaryLocation) ? labelForLocation(companyData.primaryLocation) : ""

  return (
    <MenuItem
      onClick={() => navigate(`/company/${companyData?.stagedId}`)}
    >
      <Grid container justifyContent={"space-between"} flexWrap={"initial"}>
        <Typography variant={"body1"} sx={{mr: "25px"}}>{nameToUse}</Typography>
        <Typography variant={"caption"}>{subtitle}</Typography>
      </Grid>
    </MenuItem>
  )
}

export default CompanyMenuItem
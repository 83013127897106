import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  MobileStepper,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import styled from "@emotion/styled";
import {SectorLevelPropTypes} from "../../schema/sector";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {iconForNAICS} from "../../helper/sector";
import CasinoIcon from '@mui/icons-material/Casino';


const TooltipTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
}));

const CardBoundary = styled(Card)(({ theme }) => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "8px",
  width: "500px",
  padding: "1px",
  transition: "transform 0.15s ease-in-out",
  "&:hover": {
    // transform: "scale3d(1.01, 1.01, 1)",
    // background: "#fafafa"
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));


export const SectorDisplayCard = (props) => {
  const {levels} = props

  const theme = useTheme();
  const [level, setLevel] = useState(0);

  useEffect(() => {
    setLevel(levels.length - 1)
  }, []);

  const handleDeeper = () => {
    setLevel((prevLevel) => prevLevel + 1);
  };

  const handleShallower = () => {
    setLevel((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <CardBoundary variant="outlined" sx={{mb: "10px"}}>

      {/*Layout Grid*/}
      <Box
        sx={{
          display: 'grid',
          gap: 0,
          gridTemplateColumns: '50px 75px 75px 128px repeat(5, 1fr)',
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"icon codetype code level . likelihoodicon likelihood likelihood ."
                              "des des des des des des des des des"`,
          alignItems: 'center'
        }}
      >


        {/*Thematic Icon*/}
        <Box sx={{gridArea: "icon", ml: "10px", mt: "3px", color: "#666666"}}>
          {iconForNAICS(levels[level].code)}
        </Box>


        {/*Type of Classification System Used*/}
        <Box sx={{gridArea: "codetype"}}>
          <Typography variant="h6">{levels[level].type}</Typography>
        </Box>


        {/*Numeric Code*/}
        <Box sx={{gridArea: "code"}}>
          <Typography>{levels[level].code}</Typography>
        </Box>


        {/*Level Selector Widget*/}
        <Box sx={{gridArea: "level"}}>
          <MobileStepper
            variant="dots"
            steps={levels.length}
            position="static"
            activeStep={level}
            sx={{padding: "0px"}}
            nextButton={
              <Tooltip
                  title={<TooltipTypography>{`Increase granularity`}</TooltipTypography>}
                  placement={"top"}
                  arrow
              >
                <IconButton size="small" onClick={handleDeeper} disabled={level === levels.length - 1}>
                  <AddCircleIcon/>
                </IconButton>
              </Tooltip>
            }
            backButton={
              <Tooltip
                  title={<TooltipTypography>{`Decrease granularity`}</TooltipTypography>}
                  placement={"top"}
                  arrow
              >
                <IconButton size="small" onClick={handleShallower} disabled={level === 0}>
                  <RemoveCircleIcon/>
                </IconButton>
              </Tooltip>
            }
          />
        </Box>


        {/*Dice Icon for Likelihood*/}
        <Box sx={{
          display: "flex",
          gridArea: "likelihoodicon",
          color: "#666666",
          alignItems: "center",
          justifyContent: "center",
          visibility: (levels[level].likelihood === -1) ? "hidden" : "visible"
        }}>
          <Tooltip
              title={<TooltipTypography>{`Likelihood`}</TooltipTypography>}
              placement={"top"}
              arrow
          >
            <CasinoIcon sx={{height: "20px", width: "20px"}}/>
          </Tooltip>
        </Box>


        {/*Likelihood Measurement Bar*/}
        <Box
          sx={{
            gridArea: "likelihood",
            visibility: (levels[level].likelihood === -1) ? "hidden" : "visible"
          }}
        >
          <Tooltip
              title={
                <TooltipTypography>
                  {`Likelihood (according to source): ${levels[level].likelihood}`}
                </TooltipTypography>}
              placement={"top"}
              arrow
          >
            <BorderLinearProgress
              variant="determinate"
              value={levels[level].likelihood * 100}
              sx={{width: "100%", height: "16px"}}>
            </BorderLinearProgress>
          </Tooltip>
        </Box>


        {/*Textual Description of the Category*/}
        <Box sx={{gridArea: "des", ml: "10px", mb: "3px"}}>
          <Typography variant={"caption"}>
            {levels[level].description}
          </Typography>
        </Box>

      </Box>
    </CardBoundary>
  )
}

SectorDisplayCard.propTypes = {
  levels: PropTypes.arrayOf(SectorLevelPropTypes)
}
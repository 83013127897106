import React, { useState } from "react";
import { Box, Modal, Grid } from "@mui/material";

import {
  ModalBoxComp,
  ModalGridComp,
  StyledInputBase,
  TypoGraphyNoResult,
} from "./DiscoverCompanyStyled";
import CloseIcon from "@mui/icons-material/Close";
import { getCompanyDetails, getDiscoverCompany } from "../../api/searchApi";
import defaultImageUrl from "../../assets/defaultImage.svg";
import SelectCompanyData from "./SelectCompanyData";
import DiscoverImageLoad from "./DiscoverImageLoad";

const boxModal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "30px",
  border: "1px solid rgba(88, 89, 91, 0.3)",
};

const noData = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  overflowY: "auto",
  height: "400px",
};
const DiscoverCompany = ({ discoverModalOpen, setDisCoverModalOpen }) => {
  const [inputValue, setInputValue] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [imageLoadData, setImageLoadData] = useState(false);
  const [companyDetailData, setCompanyDetailsData] = useState("");
  const [defaultData, setDefaultData] = useState(false);
  const handleClose = () => {
    setDisCoverModalOpen(false);
  };

  const handleDetailApi = async (company_id) => {
    const payload = {
      Company_ID: company_id,
    };
    const res = await getCompanyDetails(payload);
    if (res) {
      if (res?.status === 200) {
        setCompanyDetailsData(res?.data);
        setDefaultData(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleSearchData = async (e) => {
    setInputValue(e.target.value);
    const payload = {
      search_value: e.target.value,
    };
    const res = await getDiscoverCompany(payload);
    if (res) {
      if (res?.status === 200) {
        setCompanyData(res?.data?.companies);
      } else {
        console.log("error");
      }
    }
  };

  const CompanyList = companyData?.map((item) => {
    return item["_source"];
  });

  return (
    <div>
      <Modal
        open={discoverModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBoxComp>
          <ModalGridComp>
            <Box sx={boxModal}>
              <img
                src="/Image/search-normal.svg"
                width="32px"
                height="32px"
                alt="alt"
              />
              <StyledInputBase
                value={inputValue}
                onChange={(e) => handleSearchData(e)}
                placeholder="Enter a Company Name"
                inputProps={{ "aria-label": "search" }}
              />
              <CloseIcon
                onClick={() => handleClose()}
                sx={{ marginRight: "20px", cursor: "pointer" }}
              />
            </Box>
            <Grid
              sx={
                CompanyList?.length == 0
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "30px",
                    }
              }
              container
            >
              <Grid sx={noData} item>
                <Grid item>
                  {CompanyList?.length == 0 ? (
                    <Grid>
                      <TypoGraphyNoResult align="center">
                        {" "}
                        No Result Found
                      </TypoGraphyNoResult>
                    </Grid>
                  ) : (
                    CompanyList?.map((searchCompany) => {
                      return (
                        <DiscoverImageLoad
                          handleDetailApi={handleDetailApi}
                          searchCompany={searchCompany}
                        />
                      );
                    })
                  )}
                </Grid>
              </Grid>
              {CompanyList?.length !== 0 && (
                <>
                  <Grid style={{ flex: "1" }} item>
                    <SelectCompanyData
                      companyDetailData={companyDetailData}
                      defaultData={defaultData}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </ModalGridComp>
        </ModalBoxComp>
      </Modal>
    </div>
  );
};

export default DiscoverCompany;

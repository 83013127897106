import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {alphaSearchTheme} from "./theme";
import AuthProvider from "./components/AuthProvider";
import {APIProvider} from "@vis.gl/react-google-maps";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ThemeProvider theme={alphaSearchTheme}>
        <CssBaseline>
          <AuthProvider>
            <APIProvider
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY}
              onLoad={() => console.log('Maps API has loaded.')}>
                <App />
            </APIProvider>
          </AuthProvider>
        </CssBaseline>
      </ThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
)

import React, {useState} from "react";
import {Box, Divider, Drawer, Grid, Typography,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {CrossGrid} from "../companyModalFile/CompanyStyled";
import MetricCard from "../Viz/MetricCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {DollarFormatter, EmployeeFormatter} from "../../helper/formatting";
import GroupsIcon from '@mui/icons-material/Groups';
import {useTheme} from "@mui/material/styles";
import {SocialMediaBar} from "../Common/SocialMediaBar";
import ModalVerticalTabs from "./ModalVerticalTabs";
import {QualityIndicator} from "../Common/QualityIndicator";
import MultiSourceDataPointChip from "../Common/MultiSourceDataPointChip";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddToListModal from "../AddToListModal";

const BoxWraper = styled(Box)(({ theme }) => ({
  maxWidth: "809px",
  // minWidth: "700px",
  width: "100%",
  padding: "22px",
  "@media (max-width: 600px)": {
    maxWidth: "600px",
    padding: "16px",
  },
}));


const CompanyModal = ({ open, setOpen, companyDetail, imageData}) => {
  const [addToListModalOpen, setAddToListModalOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleClick = () => {
    setOpen(false);
  };

  const handleAddToList = () => {
    setOpen(false);
    setAddToListModalOpen(true);

  }

  const theme = useTheme();

  return (
    <>
      <AddToListModal
        open={addToListModalOpen}
        onClose={() => setAddToListModalOpen(false)}
        stagedId={companyDetail?.stagedId}
      />
      <Drawer
        open={open}
        onClose={() => handleClose()}
        anchor={"right"}
        sx={{scrollbarGutter: "stable both-edges", overflowY: "clip", maxHeight: "100%"}}
      >
        <BoxWraper>
          <Grid container justifyContent={"space-between"} flexWrap={"initial"} sx={{mb: "5px"}}>
            <Grid item>
              <Grid container gap={"13px"} alignItems={"center"}>
                <Grid item>
                  <img
                    src={imageData}
                    height="74px"
                    width="74px"
                    alt="logo"
                    style={{
                      borderRadius: "12px",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography variant={"h4"}>
                      {companyDetail?.primaryName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CrossGrid item onClick={() => handleClick()}>
              <CloseIcon fontSize="14px" />
            </CrossGrid>
          </Grid>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 100px",
              gap: 0,
              alignItems: "center",
            }}
          >
            <Box sx={{pb: "5px"}}>
              {companyDetail?.primaryTelephone &&
                <MultiSourceDataPointChip
                  dataPointName={"phone number"}
                  primaryDataPoint={companyDetail?.primaryTelephone}
                  alternateDataPoints={companyDetail?.alternateTelephones}
                  maxDataWidth={"120px"}
                  mr={"8px"}
                  iconRenderer={
                    (props) => {
                      return (
                        <LocalPhoneIcon
                          sx={{
                            color: props.color,
                            height: props.height,
                            width: props.width
                          }}
                        />
                      )
                    }
                  }
                />
              }
              {companyDetail?.primaryWebsite &&
                <MultiSourceDataPointChip
                  dataPointName={"website"}
                  primaryDataPoint={companyDetail?.primaryWebsite}
                  alternateDataPoints={companyDetail?.alternateWebsites}
                  iconLink={
                    companyDetail?.primaryWebsite.includes("http", 0)
                      ? companyDetail?.primaryWebsite
                      : `https://${companyDetail?.primaryWebsite}`}
                  maxDataWidth={"250px"}
                  mr={"8px"}
                  iconRenderer={
                    (props) => {
                      return (
                        <LanguageIcon
                          sx={{
                            color: props.color,
                            height: props.height,
                            width: props.width
                          }}
                        />
                      )
                    }
                  }
                />
              }
              {companyDetail?.stagedId &&
                <MultiSourceDataPointChip
                  primaryDataPoint={companyDetail?.stagedId}
                  dataPointMargins={"0px"}
                  dataPointName={"Alphasearch ID"}
                  maxDataWidth={"0px"}
                  mr={"8px"}
                  iconRenderer={
                    (props) => {
                      return (
                        <FingerprintIcon
                          sx={{
                            color: props.color,
                            height: props.height,
                            width: props.width
                          }}
                        />
                      )
                    }
                  }
                />
              }
              <MultiSourceDataPointChip
                primaryDataPoint={"Add to List"}
                dataPointMargins={"0px"}
                dataPointName={"Add to List"}
                maxDataWidth={"0px"}
                copyEnabled={false}
                mr={"8px"}
                iconRenderer={
                  (props) => {
                    return (
                      <AddCircleOutlineIcon
                        onClick={handleAddToList}
                        sx={{
                          color: props.color,
                          height: props.height,
                          width: props.width
                        }}
                      />
                    )
                  }
                }
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                justifySelf: "center",
                justifyContent: "center",
                justifyItems: "center",
                pb: "5px"
              }}
            >
              <SocialMediaBar
                links={{
                  linkedin: companyDetail?.social?.linkedinUrl,
                  instagram: companyDetail?.social?.instagramUrl,
                  twitter: companyDetail?.social?.twitterUrl,
                  facebook: companyDetail?.social?.facebookUrl,
                }}
                justifyContent={"flex-end"}
                iconSize="24px"
              />
            </Box>
          </Box>
          <Divider sx={{marginTop: "0px", marginBottom: "20px"}}/>
            <Box
                // sx={{justifyContent: 'space-evenly'}}
              sx={{
                display: 'grid',
                gap: 2,
                // gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateColumns: 'repeat(2, 275px) 150px',
                justifyItems: "center"
              }}
            >
            <MetricCard {...{
              metricName: "Revenue",
              iconRenderer: (settings) =>
                <AttachMoneyIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.money.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.money.light,
              pointEstimate: companyDetail?.revenue?.pointEstimate,
              lowerBound: companyDetail?.revenue?.lowerBound,
              upperBound: companyDetail?.revenue?.upperBound,
              dataPoints: companyDetail?.revenue?.dataPoints,
              numberFormatter: DollarFormatter
            }}/>
            <MetricCard {...{
              metricName: "Headcount",
              iconRenderer: (settings) =>
                <GroupsIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.headcount.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.headcount.light,
              pointEstimate: companyDetail?.harmonizedHeadcount?.pointEstimate,
              lowerBound: companyDetail?.harmonizedHeadcount?.lowerBound,
              upperBound: companyDetail?.harmonizedHeadcount?.upperBound,
              dataPoints: companyDetail?.harmonizedHeadcount?.dataPoints,
              numberFormatter: EmployeeFormatter
            }}/>
            <QualityIndicator value={(companyDetail?.qualityScore / 42) * 100}/>
          </Box>
          <Divider sx={{marginTop: "20px", marginBottom: "20px"}}/>
          <Grid container>
            <ModalVerticalTabs companyDetail={companyDetail}/>
          </Grid>
        </BoxWraper>
      </Drawer>
    </>
  );
};
export default CompanyModal;

import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import styled from "@emotion/styled";


const StyledBackdrop = styled(Backdrop) (() => ({
    zIndex: 99,
    color: "#000",
  })
)

export default function LoadingBackdrop(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props?.open);
  }, [props]);

  return (
    <StyledBackdrop open={open}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}

import styled from "@emotion/styled";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import CompanyDetailSection from "./CompanyDetailSection";

const CompanyDetailMetricGrid = ({children}) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('cdLg'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('cdMd'));



  let gridTemplateColumns, gridTemplateAreas, justifyContent;

  if (matchesLg) {
    justifyContent = 'flex-start';
    gridTemplateColumns = 'repeat(3, 1fr)'
    gridTemplateAreas =
    `"rev hc rpe"
    "hctot hcfte hcweb"`
  } else if (matchesMd) {
    justifyContent = 'flex-start';
    gridTemplateColumns = 'repeat(2, 1fr)'
    gridTemplateAreas =
      `"rev hc"
       "rpe hctot"
       "hcfte hcweb"`
  } else {
    justifyContent = 'center';
    gridTemplateColumns = '1fr'
    gridTemplateAreas =
      `"rev"
       "hc"
       "rpe" 
       "hctot"
       "hcfte"
       "hcweb"`
  }

  return (
    <Box sx={{display: "flex", justifyContent: justifyContent}}>
      <CompanyDetailSection title="Metrics" padding={"15px"}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            gap: 2,
            gridTemplateRows: 'auto',
            gridTemplateAreas: gridTemplateAreas,
          }}
        >
          {children}
        </Box>
      </CompanyDetailSection>
    </Box>

  )
}

export default CompanyDetailMetricGrid
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  TypoGraphyText,
  TypoGraphyTextFont,
  TypoGraphyDescriptionText,
} from "./DiscoverCompanyStyled";
import {
  DescriptionTypography,
  SizingKeyTypography,
  SizingLabelTypography,
} from "../companyModalFile/CompanyStyled";
import defaultImageUrl from "../../assets/defaultImage.svg";
import { Box, Modal, Grid, Link, Divider } from "@mui/material";
const modalData = {
  display: "flex",
  gap: "25px",
  marginBottom: "31px",
  overflowY: "auto",
  height: "400px",
};

const ImageGrid = styled(Grid)`
  width: 48px;
  height: 48px;
  background-color: #e1eaff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectCompanyData = ({ companyDetailData, defaultData }) => {
  const [isValid, setIsValid] = useState(null);
  const {
    Website,
    Company_Logo_URL,
    Company_Name,
    Country,
    Headquarters,
    Description,
  } = companyDetailData;

  const overviewSizing = [
    {
      image: "/Image/employee.svg",
      label: "Total Employees",
      key: "Employees",
    },
    {
      image: "/Image/revenue.svg",
      label: "Total Revenue",
      key: "Revenue",
    },
    {
      image: "/Image/visit.svg",
      label: "Website Visits",
      key: "Visits",
    },
  ];

  const checkImageValidity = () => {
    const img = new Image();
    img.src = Company_Logo_URL;

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };
  useEffect(() => {
    checkImageValidity();
  }, [Company_Logo_URL]);

  return (
    <Grid sx={modalData} container>
      <Grid item>
        {defaultData && (
          <img
            style={{
              width: 36,
              height: 35,
              borderRadius: "12px",
            }}
            src={isValid ? Company_Logo_URL : defaultImageUrl}
          />
        )}
      </Grid>
      <Grid item>
        <TypoGraphyTextFont>{Company_Name} </TypoGraphyTextFont>
        <Link
          href={`http://${Website}`}
          target="_blank"
          underline="none"
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
            textDecoration: "underline",
            color: "#4764F6",
          }}
        >
          {Website}
        </Link>
      </Grid>
      <Grid container>
        <TypoGraphyText>{Headquarters}</TypoGraphyText>
      </Grid>
      <Grid container>
        <TypoGraphyDescriptionText>{Description}</TypoGraphyDescriptionText>
      </Grid>
      {defaultData && (
        <Grid
          style={{ border: "1px solid rgba(0, 0, 0, 0.12)", height: "0px" }}
          container
        >
          <Divider />
        </Grid>
      )}
      {defaultData && (
        <Grid container flexDirection={"column"} gap={"27px"}>
          <Grid item>
            <DescriptionTypography>Sizing</DescriptionTypography>
          </Grid>
          <Grid item>
            <Grid container gap={"15px"}>
              {overviewSizing?.map((item) => {
                const { image, key, label } = item;
                return (
                  <Grid
                    item
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      minWidth: "200px",
                      padding: "19px 14px 21px 14px",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid container gap={"13px"}>
                      <ImageGrid item>
                        <img
                          src={image}
                          width="24px"
                          height="24px"
                          alt="Internet Down"
                        />
                      </ImageGrid>
                      <Grid item>
                        <Grid container flexDirection={"column"} gap={"10px"}>
                          <Grid item>
                            <SizingLabelTypography>
                              {companyDetailData[key] || key}
                            </SizingLabelTypography>
                          </Grid>
                          <Grid item>
                            <SizingKeyTypography>{label}</SizingKeyTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default SelectCompanyData;

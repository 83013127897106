import { InputLabel } from "@mui/material";
import styled from "@emotion/styled";

export const InputLabelStyled = styled(InputLabel)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #58595b;
`;

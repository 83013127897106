export const validateFirst = (firstname) => {
  if (!firstname) {
    return "First name is required";
  } else if (typeof firstname !== "string" || firstname?.length < 2) {
    return `First name must be at least 2 characters`;
  } else return "";
};

export const validateLast = (lastname) => {
  if (!lastname) {
    return "Last name is required";
  } else if (typeof lastname !== "string" || lastname?.length < 3) {
    return `Last name must be at least 3 characters`;
  } else return "";
};

export const validateEmail = (email) => {
  if (!email) {
    return "Email is Required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return "Invalid Email Address";
  } else return "";
};

export const validatePassword = (password) => {
  if (!password) {
    return "Password is required";
  } else if (typeof password !== "string" || password?.length < 8) {
    return `Passwords must be at least 8 characters long`;
  } else if (password?.length > 0) {
      let PASSWORD_REGEX =
        /^(?=.*([A-Z]){1,})(?=.*[!^%@#$&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$/
    if (!PASSWORD_REGEX.test(password)) {
      return "Minimum one lowercase, uppercase, number and special character required";
    } else return "";
  } else return "";
};

export const validateConfirmPassword = (password, cpassword) => {
  if (!cpassword) {
    return "Password confirmation is required";
  } else if (password !== cpassword) {
    return "Password does not match";
  } else return "";
};

export const validateCompany = (companyname) => {
  if (!companyname) {
    return "Company is required";
  } else return "";
};

import React, {useEffect, useState} from "react";
import MainLayout from "../../components/layouts/MainLayout";
import {Box, Grid, InputBase, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import searchCompany from "../../assets/searchbldgs.png";
import discoverCompany from "../../assets/officebldg.png";
import companyIndustry from "../../assets/factory.png";
import LandingSubHeader from "../../components/Landing/LandingSubHeader";
import DiscoverCompany from "../../components/DiscoverCompany/DiscoverCompany";
import ComingSoonDialog from "../../components/Common/ComingSoonDialog";
import NewsAndNotes from "../../components/NewsAndNotes";
import {getStories} from "../../api/site";
import {LocationCard} from "../../components/Common/LocationCard";

export const WrapBox = styled(Box)(({ theme }) => ({
  maxWidth: "1035px",
  marginTop: "100px",
  border: "1px solid rgba(88, 89, 91, 0.3)",
  boxShadow: "0px 4px 14px rgba(74, 44, 245, 0.2)",
  borderRadius: "8px",
  display: "flex",
  maxHeight: "80px",
}));

const CardComp = styled(Grid)(({ theme }) => ({
  maxWidth: " 382px",
  maxHeight: "215px",
  background: "#F6F4FF",
  borderRadius: "8px",
  boxShadow: "none",
}));

const Typographytext = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: "22px",
  color: "#000000",
  lineHeight: "28px",
}));

const GridComp = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px",
  height: "100%",
  cursor: "pointer",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "0.5px solid #D7DBDD",
  backgroundColor: "#FFFFFF",
  display: "flex",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    width: "700px",
    background: "#FFFFFF",
    borderRadius: "1px solid #58595B",
    borderRadius: "6px",
    "@media (max-width:1443px)": {
      maxWidth: "650px",
      width: "100%",
    },
  },
}));

const StyledInputBaseRecently = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    // width: "200px",
    background: "#FFFFFF",
    borderRadius: "1px solid #58595B",
    borderRadius: "6px",
    "@media (max-width:1443px)": {
      maxWidth: "650px",
      width: "100%",
    },
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  padding: "102px 0px 0px 0px",
  "@media (max-width: 900px)": {
    padding: "66px 0px 0px 0px",
  },
}));

const Pictograph = styled.img ({
  scale: "70%"
})

const MainContainer = styled(Grid)`
  display: flex;
  gap: 35px;
  padding: 95px 84px 20px 66px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding: 95px 15px 20px 15px;
  }
`;

function Landing() {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [discoverModalOpen, setDisCoverModalOpen] = useState(false);
  const [isUnimplemented, setIsUnimplemented] = useState(false)
  const [stories, setStories] = useState([])

  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const pathName = window.location.pathname;


  useEffect(() => {
    getStories(token)
      .then((response) => {
        setStories(response.data);
      });
  }, [])

  const handleChangePopOpen = (index, model, industryData) => {
    if (index === 0) {
      navigate("/lists/companies/")
    } else if (index === 1) {
      setIsUnimplemented(true)
      // setDisCoverModalOpen(true);
    } else {
      setIsUnimplemented(true)
      // setQuestionSelected([...questionSelected, model, industryData]);
    }
  };

  return (
    <Grid>
      <MainLayout
        pathName={pathName}
        search={searchQuery}
        setSearch={setSearchQuery}
        setPage={setPage}
      >
        <Wrapper>
          <LandingSubHeader/>
          <MainContainer container>
            <Box sx={{maxWidth: "40%", minWidth: "382px"}}>
              <NewsAndNotes
                stories={stories}
              />
            </Box>
            <Grid item>
              <ComingSoonDialog
                open={isUnimplemented}
                onClose={() => setIsUnimplemented(false)}
              />
              <Grid container columnGap={"50px"} rowGap={"25px"}>
                <CardComp>
                  <GridComp
                    onClick={() =>
                      handleChangePopOpen(0, "model", "industryInclude")
                    }
                    container
                    flexWrap={"inherit"}
                  >
                    <Grid item>
                      <Typographytext>{"Search Companies by Characteristics"}</Typographytext>
                    </Grid>
                    <Grid item>
                      <Pictograph src={searchCompany} />
                    </Grid>
                  </GridComp>
                </CardComp>
                <CardComp>
                  <GridComp
                    onClick={() =>
                      handleChangePopOpen(1, "model", "industryInclude")
                    }
                    container
                    flexWrap={"inherit"}
                  >
                    <Grid item>
                      <Typographytext>{"Discover Similar Companies"}</Typographytext>
                    </Grid>
                    <Grid item>
                      <Pictograph src={discoverCompany} />
                    </Grid>
                  </GridComp>
                </CardComp>
                <CardComp>
                  <GridComp
                    onClick={() =>
                      handleChangePopOpen(2, "model", "industryInclude")
                    }
                    container
                    flexWrap={"inherit"}
                  >
                    <Grid item>
                      <Typographytext>{"Browse Companies by Industry"}</Typographytext>
                    </Grid>
                    <Grid item>
                      <Pictograph src={companyIndustry} />
                    </Grid>
                  </GridComp>
                </CardComp>
              </Grid>
            </Grid>
          </MainContainer>
        </Wrapper>
      </MainLayout>
      {discoverModalOpen && (
        <DiscoverCompany
          discoverModalOpen={discoverModalOpen}
          setDisCoverModalOpen={setDisCoverModalOpen}
        />
      )}
    </Grid>
  );
}

export default Landing;

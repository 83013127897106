import { TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const TextFieldStyled = styled(TextField)`
  border: 1px solid #58595b;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(88, 89, 91, 0.48);
`;

export const HideStyled = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
`;

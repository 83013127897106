import {Chip, Tooltip, Typography} from "@mui/material";
import styled from "@emotion/styled";
import React from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
}));

const HardStyledConstraint = styled(Chip)(({ theme }) => ({
  marginBottom: "5px",
  marginLeft: "3px",
  background: "#FFE6FF",
  color: "#9900CE"
}));

const SoftStyledConstraint = styled(Chip)(({ theme }) => ({
  marginBottom: "5px",
  marginLeft: "3px",
  background: "#FFF2E6",
  color: "#CE6300"
}));

export const HardConstraint = () => {
  return (
    <Tooltip
      title={<StyledTypography>Hard constraints exclude companies from the result set that don't meet the criteria</StyledTypography>}
      placement={"left-start"}
      arrow
    >
      <HardStyledConstraint size={"small"} label={"H"}/>
    </Tooltip>
  )
}

export const SoftConstraint = () => {
  return (
    <Tooltip
      title={<StyledTypography>Soft constraints are additive; the more of these a company meets, the higher up in the search results it will appear</StyledTypography>}
      placement={"left-start"}
      arrow
    >
      <SoftStyledConstraint size={"small"} label={"S"}/>
    </Tooltip>
  )
}


import {Box, Card, CardContent, Divider, Typography} from "@mui/material";
import React from "react";


const CompanyDetailSection = props => {
  const {
    title,
    children,
    padding
  } = props

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: padding,
        minWidth: "545px"
      }}
    >
      <Card
        variant={"outlined"}
        sx={{
          // height: "500px",
          minWidth: "545px",
          // borderRadius: "16px"
          border: '0px solid white'
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
              {title}
          </Typography>
          <Divider sx={{marginTop: "16px", marginBottom: "16px"}}/>
            {children}
        </CardContent>
      </Card>
    </Box>
  )
}


export default CompanyDetailSection
import styled from "@emotion/styled";
import {Box ,Grid ,InputBase,Typography} from "@mui/material";
export const ModalBoxComp= styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth:"900px",
    minHeight:"400px",
    maxHeight:"520px",
    background:"#FFFF",
    boxShadow: 24,
    width:"100%",
    p: 4,
    borderRadius:"10px"
  }));


  export const ModalGridComp= styled(Grid)(({ theme }) => ({ 
    padding:"17px 22px 0px 22px"
  }));

  export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.9, 1.9, 1.9, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
      width: "700px",
      background: "#FFFFFF",
      borderRadius: "1px solid #58595B",
      borderRadius: "6px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "25px",
      cursor:"pointer",
      "@media (max-width:1611px)": {
        maxWidth: "600px",
        width: "100%",
      },
    },
  }));


  export const TypoGraphyText = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "25px",
    marginBottom: "5px",
    color: "#000000"
  }));  

  export const TypoGraphyTextFont = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "25px",
    marginBottom: "5px",
    color: "#000000"
  })); 

  export const TypoGraphyDescriptionText = styled(Typography)(({ theme }) => ({
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#58595b"
  })); 

  export const TypoGraphyNoResult = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "25px",
    marginBottom: "5px",
    color: "#FF0000",
  })); 

  export const DividerGridStyle = styled(Grid)`
  display: inline-block;
  min-height:400px;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
`;
  


import styled from "@emotion/styled";
import {Grid, Tooltip, Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@emotion/react";
import {EmployeeFormatter} from "../../helper/formatting";

const TooltipTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
}));

const VizMarker = ({
    textGlyph,
    color,
    title,
    leftPx,
    scale = 1,
    zIndex= 1
}) => {
    return(
        <Tooltip
            title={<TooltipTypography>{title}</TooltipTypography>}
            placement={"top"}
            arrow
        >
            <div style={{
                position: "absolute",
                top: `-${Math.round(scale * 20)}px`,
                left: leftPx,
                userSelect: "none",
                zIndex: String(zIndex),
                WebkitTextStroke: "white",
                WebkitTextStrokeWidth: "1px",
                transform: "translate(-50%, 0%)",  // ensure vertically centered on pixel
            }}>
                <Typography
                    sx={{
                        color: color,
                        fontSize: `${Math.round(scale * 25)}px`,
                        fontWeight: 800,
                        webkitTransition: "font-size 0.15s",
                        transition: "font-size 0.15s",
                        '&:hover': {
                            fontSize: `${Math.round(1.15 * scale * 25)}px`,
                            transform: "translate(0%, -15%)"
                        }
                    }}
                >{textGlyph}</Typography>
            </div>
        </Tooltip>
    )
}

export default function LocationViz(props) {
    const {
        pointEstimate,
        lowerBound,
        upperBound,
        dataPoints,
        width = 300,
        formatter = EmployeeFormatter
    } = props

    // TODO: add Formatter for title
    // const width = 200;
    const theme = useTheme();
    const intervalGlyph = '\u25bc';

    let observations;

    observations = [pointEstimate, lowerBound, upperBound, ...dataPoints]

    let maxObs = Math.max(...observations);
    let minObs = Math.min(...observations);

    let maxNo, minNo, dist, lbPx, pePx, ubPx;

    if (maxObs > upperBound) {
        maxNo = upperBound + 2 * (maxObs - upperBound);
    } else {
        maxNo = upperBound / 0.84;
    }

    if (minObs < lowerBound) {
        minNo = lowerBound - 2 * (lowerBound - minObs);
    } else {
        minNo = lowerBound * 0.84;
    }

    dist = maxNo - minNo;

    let lbRat = (lowerBound - minNo) / dist
    let ubRat = (upperBound - minNo) / dist
    let peRat = (pointEstimate - minNo) / dist

    // lbPx = Math.max(0, Math.round(lbRat * width));
    // ubPx = Math.round(ubRat * width);
    // pePx = Math.round(peRat * width);

    lbPx = `max(0px, calc(${width} * ${lbRat})`
    ubPx = `calc(${width} * ${ubRat})`
    pePx = `calc(${width} * ${peRat})`

    return (
        <Grid  // Bounding box
            sx={{
                position: "relative",
                width: width,
                paddingTop: "25px",
                height: "50px",
                zIndex: 1
            }}
        >
            <Grid  // Background box
                sx={{
                    width: width,
                    height: "25px",
                    position: "absolute",
                    zIndex: 2,
                    background: `linear-gradient(
                      90deg, rgba(255,255,255,1) 0%, 
                      rgba(160,19,142,0.15) ${Math.round(lbRat * 100)}%, 
                      rgba(160,19,142,1) ${Math.round(peRat * 100)}%, 
                      rgba(160,19,142,0.15) ${Math.round(ubRat * 100)}%, 
                      rgba(255,255,255,1) 100%);`
                }}
            >
                <Grid  // Confidence bands box
                    sx={{
                        width: `calc(calc(${ubPx} - ${lbPx}) + 2px)`,  // add back 1/2 line width and account for marginLeft adjustment too
                        marginLeft: `calc(${lbPx} - 1px)`,  // start 1/2 line width back
                        height: "25px",
                        position: "absolute",
                        borderRightStyle: "dashed",
                        borderRightColor: "dimgray",
                        borderRightWidth: "2px",
                        borderLeftStyle: "dashed",
                        borderLeftColor: "dimgray",
                        borderLeftWidth: "2px",
                        zIndex: 3
                    }}
                />
                <VizMarker
                    textGlyph={intervalGlyph}
                    color={theme.palette.altPalette.main}
                    title={`Model Point Estimate: ${formatter.format(pointEstimate)}`}
                    leftPx={pePx}
                    scale={1.25}
                    zIndex={6}
                />
                <VizMarker
                    textGlyph={intervalGlyph}
                    color={"gray"}
                    title={`Model Lower Bound: ${formatter.format(lowerBound)}`}
                    leftPx={lbPx}
                    zIndex={5}
                />
                <VizMarker
                    textGlyph={intervalGlyph}
                    color={"gray"}
                    title={`Model Upper Bound: ${formatter.format(upperBound)}`}
                    leftPx={ubPx}
                    zIndex={5}
                />
                {dataPoints.map(function(dp){
                    const dpRatio = (dp - minNo) / dist
                    return (
                        <VizMarker
                            textGlyph={"\u2022"}
                            color={theme.palette.primary.main}
                            title={`Data Point: ${formatter.format(dp)}`}
                            // leftPx={Math.round(((dp - minNo) / dist) * width)}
                            leftPx={`calc(${dpRatio} * ${width})`}
                            zIndex={4}
                        />
                    );
                })}
            </Grid>
        </Grid>
    );
}
import React, {useEffect, useRef, useState} from "react";
import {styled} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../assets/logo.png";
import {useMatch, useNavigate} from "react-router-dom";
import {logoutUser} from "../../api/authApi";
import {Alert, Avatar, Divider, Grid, useTheme} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {getCompanyDetails, getRecentView,} from "../../api/searchApi";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {MainBoxCompHeader, TypographytextComp, WrapBoxComp,} from "../../components/Landing/LandingSubHeaderStyled";
import CompanyDetails from "../companyDetailsData/CompanyDetails";
import RecentViewComp from "../Landing/RecentView";
import {useAuth} from "../AuthProvider";
import {randomHexString} from "../../helper/util";
import AccountModal from "../AccountModal";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "0.5px solid #D7DBDD",
  // backgroundColor: "#F2F3F4",
  backgroundColor: "#FFFF",
  display: "flex",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    // maxWidth: "100%",
    // width:"100%",
    width: "650px",
    background: "#FFFFFF",
    borderRadius: "1px solid #58595B",
    borderRadius: "6px",
    "@media (max-width:1611px)": {
      maxWidth: "600px",
      width: "100%",
    },
    // [theme.breakpoints.down("lg")]: {
    //   width: "26ch",
    // },
  },
}));

const ActiveTabTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  color: "#4A2CF5",
  fontWeight: 700,
  fontSize: "20px",
  fontStyle: "normal",
  borderBottom: "2px solid #4A2CF5",
  padding: "40px 40px 37px 40px",
  marginBottom: "-2px",
  background:
    "linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(74, 44, 245, 0.19) 100%)",
  '&:hover': {
    background: "linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(74, 44, 245, 0.30) 100%)"
  }
}));

const InactiveTabTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  color: "black",
  fontWeight: 700,
  fontSize: "20px",
  fontStyle: "normal",
  // borderBottom: "2px solid #4A2CF5",
  padding: "40px 40px 37px 40px",
  marginBottom: "-2px",
  '&:hover': {
    background: "radial-gradient(circle, whitesmoke 0%, white 75%)"
  }
}));

const HeaderButton = [
  {
    buttonName: "Lists",
    listItems: [
      "My Lists",
      "My Searches",
      "My Pipeline",
      "My Labels",
      "My Signals",
      "My Contacts",
      "My Files",
      "My Requests",
      "Tags",
    ],
  },
  {
    buttonName: "More",
    listItems: ["More 1", "More 2", "More 3"],
  },
];

const HeaderAppBar = styled(AppBar)`
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  background: #fff;
`;

const ToolBarStyle = styled(Toolbar)`
  padding: 0px 66px !important;
  @media (max-width: 1200px) {
    padding: 0px 15px !important;
  }
`;

const AvatarStyle = styled(Avatar)`
  width: 58px;
  height: 58px;
  background: #4764f6;
  @media (max-width: 900px) {
    width: 32px;
    height: 32px;
  }
`;

const pages = ["Search", "List", "More"];
export default function Header() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [getRecentViewData, setGetRecentViewData] = useState([]);
  const [recentView, setRecentView] = useState(false);
  const [companyDetailsOpen, setCompanyDetailsOpen] = useState(false);
  const [companyDetailData, setCompanyDetailsData] = useState("");
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)

  const [searchValue, setSearchValue] = useState("");

  const { token, setToken, userFirstName } = useAuth();

  const theme = useTheme();

  const routeMatchesSearch = useMatch("/lists/companies/")
  const routeMatchesList = useMatch("/lists/targets/")
  const routeMatchesNoCompany = useMatch("/company/")
  const routeMatchesStagedCompany = useMatch("/company/:stagedId")
  const routeMatchesCompany = routeMatchesNoCompany || routeMatchesStagedCompany

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setRecentView(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleKeyDown = async (event) => {
    // setLoading(true);
    setSearchValue(searchValueData);
    if (event.key === "Enter") {
      if (searchValueData?.trim()) {
        const spec = {query: {
          TermsInclude: searchValueData.split(" ")
        }}
        const searchKey = randomHexString(10)
        sessionStorage.setItem(searchKey, JSON.stringify(spec))
        navigate(`/lists/companies/${searchKey}`);
        window.location.reload();
      }
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = async () => {
    await logoutUser();
    setToken(null)
    navigate("/")
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAccount = () => {
    setAnchorEl(null);
    setIsAccountModalOpen(true)
  };
  const handleChangePassword = () => {
    setAnchorEl(null);
    navigate("/change-password");
  };
  let searchValueData;
  const handleSearch = (event) => {
    searchValueData = event.target.value;
  };

  const handleRecentView = () => {
    setRecentView(!recentView);
    try {
      getRecentView(token).then((res) => {
        if (res.status === 200) {
          setGetRecentViewData(res?.data);
        } else {
          <Alert variant="filled" severity="error">
            session out.Please Login again
          </Alert>;
        }
      });
    } catch (error) {
      console.log(error);
      // navigate("/");
    }
  };

  const handleCompayDetails = async (company_id) => {
    const payload = {
      Company_ID: company_id,
    };
    const res = await getCompanyDetails(payload);
    if (res) {
      if (res?.status === 200) {
        setCompanyDetailsData(res?.data);
        setCompanyDetailsOpen(true);
      } else {
        console.log("error");
      }
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleAccount}>Account</MenuItem>
      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      <MenuItem onClick={() => handleLogout()}>Log Out</MenuItem>
      <Divider />
      <MenuItem disabled={true} onClick={() => {}}>{"Alphasearch v" + process.env.REACT_APP_VERSION}</MenuItem>
    </Menu>
  );

  return (
    <HeaderAppBar position="fixed">
      <ToolBarStyle>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          rowGap={"12px"}
        >
          <Grid item sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#fff"
            >
              <MenuIcon fontSize="4px" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid
            item
            sx={{
              display: { xs: "none", md: "flex", cursor: "pointer" },
            }}
          >
            <Grid
              container
              columnGap={"76px"}
              alignItems={"center"}
              rowGap={"12px"}
            >
              <Grid
                onClick={() => navigate("/landing")}
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  display: { cursor: "pointer" },
                }}
              >
                <img src={logo} alt="icon" width="188px" height="36px" />
              </Grid>
              <Grid item>
                <Grid container alignItems={"center"}>
                  <Grid item onClick={() => {navigate("/lists/companies/")}}>
                    { routeMatchesSearch
                      ?
                        <ActiveTabTypography>Search</ActiveTabTypography>
                      :
                        <InactiveTabTypography>Search</InactiveTabTypography>
                    }
                  </Grid>
                  <Grid item onClick={() => {navigate("/lists/targets/")}}>
                    { routeMatchesList
                      ?
                        <ActiveTabTypography>My Lists</ActiveTabTypography>
                      :
                        <InactiveTabTypography>My Lists</InactiveTabTypography>
                    }
                  </Grid>
                  <Grid item onClick={() => {navigate("/company/")}}>
                    { routeMatchesCompany
                      ?
                        <ActiveTabTypography>Company</ActiveTabTypography>
                      :
                        <InactiveTabTypography>Company</InactiveTabTypography>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container columnGap={"28px"}>
              {window.location.pathname !== "/landing" && (
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  item
                >
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#4764F6" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      // value={searchValue}
                      onChange={(event) => handleSearch(event)}
                      onKeyDown={handleKeyDown}
                      placeholder="Search a term, industry, or a specific company"
                      inputProps={{ "aria-label": "search" }}
                    />
                    <ScheduleIcon
                      onClick={() => handleRecentView()}
                      style={{
                        fill: "rgb(88, 89, 91,0.3)",
                        marginRight: "19px",
                        cursor: "pointer",
                      }}
                    />
                  </Search>
                  {recentView && (
                    <MainBoxCompHeader ref={wrapperRef}>
                      <WrapBoxComp>
                        <TypographytextComp>Recently Viewed</TypographytextComp>
                        {getRecentViewData?.map((recentViewData, key) => {
                          return (
                            <RecentViewComp
                              recentViewData={recentViewData}
                              handleCompayDetails={handleCompayDetails}
                            />
                          );
                        })}
                      </WrapBoxComp>
                    </MainBoxCompHeader>
                  )}
                </Grid>
              )}
              <Grid item>
                <IconButton size="large" onClick={handleProfileMenuOpen}>
                  <AvatarStyle sx={{bgcolor: theme.palette.primary.main}}>{userFirstName.charAt(0).toLocaleUpperCase()}</AvatarStyle>
                </IconButton>
                {renderMenu}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {companyDetailsOpen && (
          <CompanyDetails
            companyDetailsOpen={companyDetailsOpen}
            companyDetailData={companyDetailData}
            setCompanyDetailsOpen={setCompanyDetailsOpen}
          />
        )}
      </ToolBarStyle>
    <AccountModal open={isAccountModalOpen} onClose={() => setIsAccountModalOpen(false)} />
    </HeaderAppBar>
  );
}

import {Box, Card, CardContent, Divider, LinearProgress, linearProgressClasses, Typography} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

// https://stackoverflow.com/questions/69469405/rotate-a-material-ui-linearprogress

const VerticalLinearProgress = styled(LinearProgress)(({theme}) => ({
  width: "30px",
  height: "100%",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#F5F6FA"
  },
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: theme.palette.altPalette.light
  }
}));

export const QualityIndicator = (props) => {
  const {value} = props

  return (
    <Card
      variant={"outlined"}
      sx={{
        width: "150px",
        height: "255px",
        textAlign: "center",
        borderRadius: "8px",
      }}>
      <CardContent sx={{justifyItems: "center"}}>
        <Typography variant="h5" gutterBottom>
          {"Info"}
        </Typography>
        <Divider variant="middle" sx={{marginTop: "16px", marginBottom: "16px"}}/>
        <Box
          sx={{
            width: "90px",
            height: "160px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "grid",
            gap: 0,
            gridTemplateColumns: "30px 60px"
          }}
        >
          <Box sx={{width: "30px"}}>
            <Box sx={{flexDirection: 'row', alignContent: 'space-between'}}>
              <Box>H</Box>
              <Box sx={{height: "112px"}}></Box>
              <Box>L</Box>
            </Box>
          </Box>
          <VerticalLinearProgress
            variant="determinate"
             color="secondary"
             value={value}
             sx={{
               [`& .${linearProgressClasses.bar}`]: {
                 transform: `translateY(${-value}%)!important`
               }
             }}
           />
        </Box>
      </CardContent>
    </Card>
  )
}
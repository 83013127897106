import {unauthenticated_api} from "./base";

export const loginUserUrlEncoded = async (params) => {
  try {
    const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    return await unauthenticated_api.post(`/token/`, params, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const logoutUser = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (token) {
      return await unauthenticated_api.get(`/user/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const forgotPasswordApi = async (email) => {
  try {
    return await unauthenticated_api.post(
      `/user/forgotten-password/`,
      {email: email},
      {headers: { "Content-Type": "application/json"}}
    );
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

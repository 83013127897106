export const DollarFormatter = Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    notation: "compact"
});
export const EmployeeFormatter = Intl.NumberFormat("en", {
    maximumFractionDigits: 0,
    notation: "compact"
})
export const optBoolToDes = (datapoint) => {
  if (datapoint === undefined) {
    return "No Information"
  } else if (datapoint === true) {
    return "True"
  } else {
    return "False"
  }
}
import {Divider, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography} from "@mui/material";
import React from "react";
import {Newspaper} from "@mui/icons-material";

export default function NewsStory({title, date, body_text}) {
  return (
     <ListItem alignItems="flex-start">
        <ListItemIcon>
          <Newspaper fontSize={"large"} />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography sx={{fontSize: "1.2rem", fontWeight: 600}}>
                {title}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                {`${date} — `}
              </Typography>
              <Typography variant="body1" component="span">
                {body_text}
              </Typography>

            </React.Fragment>
          }
        />
      </ListItem>
  )
}
import axios from "axios"

const get_axios_api = () => {
  if (process.env.APP_PIPELINE_STAGE === "PROD") {
    return axios.create({
      baseURL: process.env.REACT_APP_ALPHASEARCH_API_BASE_URL,
      timeout: 15000
    })
  } else {
    return axios.create({
      baseURL: process.env.REACT_APP_ALPHASEARCH_API_BASE_URL,
      timeout: 15000,
    })
  }
}

export const authenticated_api = get_axios_api()
export const unauthenticated_api = get_axios_api()

const redirectableMessages = [
  "Authentication token could not be validated. Token is expired.",
  "Authentication token could not be validated. The User session has expired",
  "Authentication token could not be validated. Token is not valid for this User.",
  "User has logged out and/or must re-authenticate."
]

// This will intercept any 401 returns from the api and cause a the browser to fully refresh the page. Because a full
// page refresh... an expired token will cause a redirect back to login.
authenticated_api.interceptors.response.use(null, (error) => {
    if (error.response.status === 401){
      if (redirectableMessages.includes(error.response.data.detail)) {
        localStorage.removeItem("authToken");
        window.location.reload();
      } else {
        return error;
      }
    } else {
      return error;
    }
  }
);

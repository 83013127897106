import {authenticated_api} from "./base";


export const getStories = async (token) => {
    try {
      if (token) {
        return await authenticated_api.get(
          `/site/stories/`,
          {headers: {Authorization: `Bearer ${token}`}});
      }
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
import {Avatar, Grid, Link, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import Global from "../../../assets/global.svg";
import people from "../../../assets/people.svg";
import {BlackDotGrid, ChipCard, ChipCardSecond,} from "./style";
import defaultImageUrl from "../../../assets/defaultImage.svg";
import PlaceIcon from '@mui/icons-material/Place';
import ComingSoonDialog from "../../../components/Common/ComingSoonDialog";

import 'jimp/browser/lib/jimp';
import {addBackground} from "../../../helper/image_processing";
import CompanyModal from "../../../components/CompanyModal/CompanyModal";
import {registerCompanyInquiry} from "../../../api/companyApi";
import {EmployeeFormatter} from "../../../helper/formatting";
import {labelForLocation} from "../../../helper/address";
import {useAuth} from "../../../components/AuthProvider";


const { Jimp } = window;  // See https://github.com/jimp-dev/jimp/issues/1194


const ListCard = ({ company }) => {
  const [open, setOpen] = useState(false);
  const [imageData, setImageData] = useState(defaultImageUrl);
  const [isUnimplemented, setIsUnimplemented] = useState(false)

  const {token} = useAuth();

  const handlePopUp = async (stagedId) => {
    registerCompanyInquiry(token, stagedId).then(setOpen(true))
  };

  const {
    primaryLogo,
    primaryName,
    primaryWebsite,
    harmonizedHeadcount,
    primaryLocation,
    descriptive,
  } = company;

  useEffect(() => {
    const getCompanyLogo = async (uri) => {
      // We don't want to use zoominfo image processing...
      const unprocessed_uri = uri.replace("w_70,h_70,c_fit", "")

      await Jimp.read(unprocessed_uri)
        .then(image => image
          .autocrop()
          .scaleToFit(70, 70))
        .then(processed =>
          processed.getBase64Async(Jimp.MIME_PNG))
        .then(imageString => {
          addBackground(imageString, 74, 74)
          .then(withBg => {
            if (withBg) {
              setImageData(withBg)
            } else {
              setImageData(imageString)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        });
    }
    getCompanyLogo(primaryLogo)
  }, [primaryLogo]);

  return (
    <>
      <CompanyModal
        open={open}
        setOpen={setOpen}
        companyDetail={company}
        imageData={imageData}
      />
      <ComingSoonDialog
        open={isUnimplemented}
        onClose={() => setIsUnimplemented(false)}
      />

      <Grid
        style={{
          marginBottom: "28px",
          background: "#FFFFFF",
          border: "1px solid rgba(88, 89, 91, 0.2)",
          borderRadius: "10px",
          padding: "26px 98px 26px 25px",
          cursor: "pointer",
          maxWidth: "1257px",
          width: "100%",
        }}
        // onClick={() => setIsUnimplemented(true)}
          onClick={() => handlePopUp(company?.stagedId)}
      >
        <Grid container gap={"20px"}>
          <Grid item>
            <Grid container gap={"13px"} alignItems={"center"}>
              <Grid item>
                <img style={{
                  height: "74px",
                  width: "74px",
                  // border: "1px solid rgba(88, 89, 91, 0.3)",
                  borderRadius: "12px" }}
                  src={imageData}
                />
              </Grid>
              <Grid item>
                <Grid container flexDirection={"column"} gap={"15px"}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000000",
                        fontWeight: 700,
                        fontSize: 20,
                      }}
                    >
                      {primaryName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container gap={"22px"} alignItems={"center"}>
                      <Grid item>
                        <Grid container gap={"4px"} alignItems={"center"}>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img width={"20px"} height={"20px"} src={Global} />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Link
                              href={`http://${primaryWebsite}`}
                              target="_blank"
                              underline="none"
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "#4764F6",
                              }}
                            >
                              {primaryWebsite}
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems={"center"} gap={"14px"}>
                          <BlackDotGrid item />
                          <Grid item>
                            <ChipCard
                              avatar={
                                <Avatar
                                  style={{ height: "16px", width: "16px" }}
                                  alt="Natacha"
                                  src={people}
                                />
                              }
                              label={`${EmployeeFormatter.format(harmonizedHeadcount?.pointEstimate)} Employees`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems={"center"} gap={"14px"}>
                          <BlackDotGrid item />
                          <Grid item>
                            <ChipCardSecond
                              // https://github.com/mui/material-ui/issues/26676
                              icon={<PlaceIcon sx={{ "&&": {color: "#9900CE", height: "18px", width: "18px"}}}/>}
                              label={labelForLocation(primaryLocation)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              style={{
                fontSize: "18px",
                lineHeight: "28px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgba(88, 89, 91, 0.9)",
              }}
            >
              {(company && Array.isArray(descriptive) && descriptive.length > 0 && descriptive[0].length > 240)
                ? descriptive[0]?.slice(0, 240) + "..."
                : descriptive?.[0]
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ListCard;

import { Grid } from "@mui/material";
import React from "react";
import {
  MainStyledTypography,
  StyledTypography,
  ItemGrid,
} from "./SubHeaderStyled.js";
import {useTheme} from "@mui/material/styles";

const SubHeader = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        position={"relative"}
        sx={{ background: theme.palette.secondary.main }}
      >
        <ItemGrid>
          <Grid
            container
            flexDirection={"column"}
            style={{ padding: "40px 0px 40px 60px" }}
          >
            <Grid item>
              <MainStyledTypography>
                Find What you 're looking for with
              </MainStyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography>AlphaSearch.</StyledTypography>
            </Grid>
          </Grid>
        </ItemGrid>
      </Grid>
    </>
  );
};

export default SubHeader;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {

  let isTokenPresent = localStorage.getItem("authToken") != null;

  return <>{isTokenPresent ? <Outlet /> : <Navigate to="/" />};</>;
};
export default PrivateRoutes;

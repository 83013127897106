import {authenticated_api} from "./base";

export const getUserList = async (token, page, rowsPerPage) => {
  try {
    const res = await authenticated_api.get(`/user/all/?page_size=${rowsPerPage}&page_num=${page}`,
      {headers: {Authorization: `Bearer ${token}`}});
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}

export const getUserDetail = async (token, email) => {
  try {
    const res = await authenticated_api.get(`/user/details/?email=${email}`,
      {headers: {Authorization: `Bearer ${token}`}});
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}

export const updateUserDetail = async (token, email, userdata) => {
  try {
    if (token) {
      return await authenticated_api.put(
        `/user/details/?email=${email}`, userdata,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteUser = async (token, userId) => {
  try {
    if (token) {
      return await authenticated_api.delete(`/user/delete/?email=${userId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUserDetailApi = async (token) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/user/profile/`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateUserDetailApi = async (token, userdata) => {
  try {
    if (token) {
      return await authenticated_api.put(
        `/user/profile/`, userdata,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updatePassword = async (token, userdata) => {
  try {
    if (token) {
      return await authenticated_api.post(
        `/user/password-change/`, userdata,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const resetPassword = async (userdata) => {
  try {
    return await authenticated_api.post(
      `/user/password-reset/`, userdata,
      {headers: {'Content-Type': 'application/json', 'accept': 'application/json'}});
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaceIcon from "@mui/icons-material/Place";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    createFilterOptions,
    Divider,
    Grid,
    InputBase,
    Slider,
    TextField,
    Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import styled from "@emotion/styled";
import {getLocationApi, getOperationModel,} from "../../api/searchApi";
import {useAuth} from "../../components/AuthProvider";
import Defaults from "../../helper/defaults";
import {HardConstraint, SoftConstraint} from "../../components/Common/ConstraintFlag";
import {DollarFormatter, EmployeeFormatter} from "../../helper/formatting";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 22,
  alignItems: "center",
  display: "flex",
  gap: "8px",
  color: "#000000",
}));

const GridWraper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "23px 16px 0",
}));

const InputBox = styled(InputBase)(({ theme }) => ({
  border: "1px solid rgba(88, 89, 91, 0.3)",
  borderRadius: "6px",
  height: "55px",
  width: "208px",
  padding: "10px",
  backgroundColor: "#F2F3F4",
  "& .css-yz9k0d-MuiInputBase-input": {
    textAlign: "center !mportant",
  },
}));

const SizeFilterBox = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: "30px",
  alignItems: "center",
}));

export default function FilterSection({
  locations, setLocations,
  termsInclude, setTermsInclude,
  termsExclude, setTermsExclude,
  industryInclude, setIndustryInclude,
  industryExclude, setIndustryExclude,
  revenueLowerBound, setRevenueLowerBound,
  revenueUpperBound, setRevenueUpperBound,
  employeesLowerBound, setEmployeesLowerBound,
  employeesUpperBound, setEmployeesUpperBound,
  onClear
}) {

  const { token } = useAuth();
  const [industryChoices, setIndustryChoices] = useState([]);
  const [locationChoices, setLocationChoices] = useState([]);

  // These references and keys are used to clear the values in unmanaged components. See clearAll()
  const [revenueSliderKey, setRevenueSliderKey] = useState("0000")
  const [employeeSliderKey, setEmployeeSliderKey] = useState("0000")
  const locationsRef = React.useRef(null)
  const termsIncludeRef = React.useRef(null)
  const termsExcludeRef = React.useRef(null)
  const industryIncludeRef = React.useRef(null)
  const industryExcludeRef = React.useRef(null)

  // Used to randomize component keys and force them to remount.
  function getRandomInt(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
  }

  const [revenueSliderValues, setRevenueSliderValues] = useState([
    Defaults.search.revenueLowerBound, Defaults.search.revenueUpperBound
  ])
  const [employeeSliderValues, setEmployeeSliderValues] = useState([
    Defaults.search.employeesLowerBound, Defaults.search.employeesUpperBound
  ])

  const handleTermInclusion = (event, value) => {
    setTermsInclude(value);
  };

  const handleTermExclusion = (event, value) => {
    setTermsExclude(value);
  };

  const handleIndustryInclusion = (event, value) => {
    setIndustryInclude(value?.map((x) => x.description));
  };

  const handleIndustryExclusion = (event, value) => {
    setIndustryExclude(value?.map((x) => x.description));
  };

  const handleRevenueBoundsChange = (event, value) => {
    setRevenueLowerBound(Math.exp(revenueSliderValues[0]));
    setRevenueUpperBound(Math.exp(revenueSliderValues[1]));
  }

  const handleRevenueSliderValuesChange = (event, value) => {
    setRevenueSliderValues(value)
  }

  const handleEmployeesBoundsChange = (event, value) => {
    setEmployeesLowerBound(Math.exp(employeeSliderValues[0]));
    setEmployeesUpperBound(Math.exp(employeeSliderValues[1]));
  }

  const handleEmployeesSliderValuesChange = (event, value) => {
    setEmployeeSliderValues(value)
  }

  const handleSelectLocation = (event, value) => {
    setLocations(value);
  }


  const clearAll = () => {
    {/*  Note ID: 54d6dd298cef4a29
    Because we use "unmanaged" components like autocompletes and sliders (we take their values rather than
    tell them what their values should be), clearing all the inputs is very tricky and inherently a bit
    hacky. We're supposed to use 'refs'. This approach doesn't seem to work because the MUI and styling
    gets in the way. Thus for the Autocomplete inputs, the strategy we adopt is to find the "Clear-All"
    button in each Autocomplete and progromatically click it. This won't work for the sliders, however,
    because they don't have a built-in clear button. Thus, for those, we adopt a strategy of making their
    'key' property managed state, and then changing that key when we want to reset the component. This
    works because React uses the 'key' prop to determine equality, and so changing it causes it to treat it
    as a new coponent and reinitialize it from scratch.
    */}
    const refs = [locationsRef, termsIncludeRef, termsExcludeRef, industryIncludeRef, industryExcludeRef]
    for (let i = 0; i < refs.length; i++) {
      const element = refs[i].current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (element) element.click();
    }

    setRevenueLowerBound(Defaults.search.revenueLowerBound)
    setRevenueUpperBound(Defaults.search.revenueUpperBound)
    setRevenueSliderKey(`${getRandomInt(1000, 9999)}`)

    setEmployeesLowerBound(Defaults.search.employeesLowerBound)
    setEmployeesUpperBound(Defaults.search.employeesUpperBound)
    setEmployeeSliderKey(`${getRandomInt(1000, 9999)}`)

    onClear()
  }

  useEffect( () => {
      getOperationModel(token).then( (response) => {
        setIndustryChoices(response?.data)
      })
    }, []
  )

  useEffect( () => {
      getLocationApi(token).then( (response) =>
        setLocationChoices(response?.data)
      )
    }, []
  )

const filterOptions = createFilterOptions({
  limit: 10
  // matchFrom: 'start',
  // stringify: (option) => option.title,
});


  return (
    <form id={"fitler-section"}>
      <Grid
        item
        sx={{
          border: " 1.5px solid rgba(88, 89, 91, 0.2)",
          borderRadius: "12px",
        }}
      >
        <List
          component="nav"
          subheader={
            <GridWraper>
              <Typography
                style={{ fontWeight: 700, fontSize: 22, color: "#4A2CF5" }}
                variant="h6"
              >
                Search Parameters
              </Typography>
              {termsInclude?.length ||
              termsExclude?.length ||
              locations?.length ||
              industryInclude?.length ||
              industryExclude?.length ? (
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    color: "#D80000",
                    cursor: "pointer",
                  }}
                  variant="h6"
                  onClick={clearAll}
                >
                  Clear All
                </Typography>
              ) : null}
              {/* <MoreVertIcon /> */}
            </GridWraper>
          }
        >
          <Divider style={{ margin: "24px 0px 12px" }} />
          <Box style={{maxWidth: "400px"}}>
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StyledTypography>
                  Terms{" "}
                  {termsInclude?.length || termsExclude?.length ? (
                    <DoneIcon sx={{ color: "#2196f3" }} />
                  ) : null}
                </StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  style={{
                    margin: 0,
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#58595B",
                  }}
                >
                  Include these terms <HardConstraint/>
                </p>
                <Autocomplete id={"terms-include-autocomplete"}
                  ref={termsIncludeRef}
                  multiple
                  freeSolo
                  options={[]}
                  onChange={handleTermInclusion}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Type any term"
                    />
                  }
                />
                <p
                  style={{
                    margin: 0,
                    marginTop: 15,
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#58595B",
                  }}
                >
                  Exclude these terms <HardConstraint/>
                </p>
                <Autocomplete id={"terms-exclude-autocomplete"}
                  ref={termsExcludeRef}
                  multiple
                  freeSolo
                  options={[]}
                  onChange={handleTermExclusion}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Type any term"
                    />
                  }
                />
              </AccordionDetails>
            </Accordion>
            {/* <StyleDivider /> */}
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StyledTypography style={{ width: "100%" }}>
                  Operating Model{" "}
                  {industryInclude?.length ||
                  industryExclude?.length ? (
                    <DoneIcon sx={{ color: "#2196f3" }} />
                  ) : null}
                </StyledTypography>
                <Grid
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "20px",
                    // width: "70%",
                  }}
                >
                  {industryInclude?.map((item) => {
                    return <Typography>{item}</Typography>;
                  })}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  Industry
                </span>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: "20px",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#58595B",
                  }}
                >
                  Include <SoftConstraint/>
                </p>
                  <Autocomplete id={"industry-include-autocomplete"}
                    ref={industryIncludeRef}
                    fullWidth={true}
                    limitTags={1}
                    multiple
                    freeSolo
                    options={industryChoices}
                    getOptionKey={(option) => option.code}
                    getOptionLabel={(option) => option.code + ": " + option.description}
                    onChange={handleIndustryInclusion}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Type any term"
                      />
                    }
                  />
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: "20px",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#58595B",
                  }}
                >
                  Exclude <HardConstraint/>
                </p>
                  <Autocomplete id={"industry-exclude-autocomplete"}
                    ref={industryExcludeRef}
                    fullWidth={true}
                    limitTags={1}
                    multiple
                    freeSolo
                    options={industryChoices}
                    getOptionKey={(option) => option.code}
                    getOptionLabel={(option) => option.code + ": " + option.description}
                    onChange={handleIndustryExclusion}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Type any term"
                      />
                    }
                  />
              </AccordionDetails>
            </Accordion>

            {/* <StyleDivider /> */}

            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "#000000",
                  }}
                >
                  Location
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#58595B",
                    lineHeight: "40px",
                  }}
                >
                  Headquaters <SoftConstraint/>
                </Typography>
                <Autocomplete
                  ref={locationsRef}
                  multiple
                  id="tags-outlined"
                  value={locations}
                  onChange={handleSelectLocation}
                  options={locationChoices}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  filterOptions={filterOptions}
                  popupIcon={<PlaceIcon />}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        borderRadius: "6px",
                        border: "1px solid rgba(88, 89, 91, 0.3)",
                      }}
                      {...params}
                      placeholder="Describe a location"
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
            {/* <StyleDivider /> */}
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "#000000",
                  }}
                >
                  Size
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#000000",
                  }}
                >
                  Revenue <HardConstraint/>
                </p>
                <SizeFilterBox>
                  <InputBox
                    disabled
                    value={DollarFormatter.format(revenueLowerBound)}
                  />
                  <InputBox
                    disabled
                    value={DollarFormatter.format(revenueUpperBound)}
                  />
                </SizeFilterBox>
                <Box sx={{ mt: 2 }}>
                  <Slider
                    key={revenueSliderKey}
                    min={Math.log(1+Defaults.search.revenueLowerBound)}
                    max={Math.log(1+Defaults.search.revenueUpperBound)}
                    value={[Math.log(revenueLowerBound), Math.log(revenueUpperBound)]}
                    step={0.05}
                    defaultValue={[Math.log(revenueLowerBound), Math.log(revenueUpperBound)]}
                    onChange={handleRevenueSliderValuesChange}
                    onChangeCommitted={handleRevenueBoundsChange}
                    scale={(x) => Math.exp(x)}
                  />
                </Box>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#000000",
                  }}
                >
                  Employee <HardConstraint/>
                </p>
                <SizeFilterBox>
                  <InputBox
                    disabled
                    value={EmployeeFormatter.format(employeesLowerBound)}
                  />
                  <InputBox
                    disabled
                    value={EmployeeFormatter.format(employeesUpperBound)}
                  />
                </SizeFilterBox>
                <Box sx={{ mt: 2 }}>
                  <Slider
                    key={employeeSliderKey}
                    min={Math.log(1+Defaults.search.employeesLowerBound)}
                    max={Math.log(1+Defaults.search.employeesUpperBound)}
                    value={[
                      Math.log(employeesLowerBound),
                      Math.log(employeesUpperBound)
                    ]}
                    step={0.05}
                    onChange={handleEmployeesSliderValuesChange}
                    onChangeCommitted={handleEmployeesBoundsChange}
                    scale={(x) => Math.exp(x)}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </List>
      </Grid>
    </form>
  );
}

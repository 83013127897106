import styled from "@emotion/styled";
import { Box, Divider, Grid, InputBase, Typography } from "@mui/material";

export const ItemGrid = styled(Grid)`
  &::before {
    content: "";
    position: absolute;
    background: url("/Image/subheader.svg");
    width: 128px;
    height: 157px;
    left: 0;
  }
  
  &::after {
    content: "";
    position: absolute;
    background: url("/Image/sub-header-right-img.png") no-repeat;
    width: 230px;
    height: 157px;
    right: 0px;
    top: 0;
  }
`;

export const WrapBox = styled(Grid)(({ theme }) => ({
  maxWidth: "1035px",
  width: "100%",
  border: "1px solid rgba(88, 89, 91, 0.3)",
  boxShadow: "0px 4px 14px rgba(74, 44, 245, 0.2)",
  borderRadius: "8px",
  display: "flex",
  maxHeight: "80px",
  "@media (max-width: 600px)": {
    maxWidth: "600px",
    width: "100%",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    // maxWidth: "100%",
    // width:"100%",
    width: "650px",
    background: "#FFFFFF",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "25px",
    borderRadius: "1px solid #58595B",
    borderRadius: "6px",
    "@media (max-width:1443px)": {
      maxWidth: "650px",
      width: "100%",
    },
    // [theme.breakpoints.down("lg")]: {
    //   width: "26ch",
    // },
  },
}));

export const StyledInputBaseRecently = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    // width: "200px",
    background: "#FFFFFF",
    borderRadius: "1px solid #58595B",
    borderRadius: "6px",
    "@media (max-width:1443px)": {
      maxWidth: "650px",
      width: "100%",
    },
  },
}));

export const SearchContainer = styled(Grid)`
  background: #ffffff;
  border: 1px solid rgba(88, 89, 91, 0.3);
  box-shadow: 0px 4px 14px rgba(74, 44, 245, 0.2);
  border-radius: 8px;
  max-width: 1035px;
  width: 100%;
  //   padding: 25px;
  height: 80px;
  justify-content: space-between;
  padding-right: 25px;
  @media (max-width: 900px) {
    max-width: 600px;
  }
  @media (max-width: 600px) {
    max-width: 400px;
    padding-right: 0px;
    flex-wrap: nowrap;
  }
`;

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  border: "1px solid rgba(88, 89, 91, 0.3)",
  width: "70px",
  height: "0px",
  left: "745px",
  top: "38px",
  right: "0px",
  transform: " rotate(90deg)",
  position: "absolute",
}));

export const RecentStyledGrid = styled(Grid)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #58595b;
  cursor: pointer;
`;

export const DividerGridStyle = styled(Grid)`
  display: inline-block;
  height: 71px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
`;

export const TypographytextComp = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "18px",
  color: "#000000",
  lineHeight: "23px",
  padding: "17px 0px 13px 23px",
}));

export const MainBoxComp = styled(Grid)(({ theme }) => ({
  position: "absolute",
  maxWidth: "762px",
  minHeight: "259px",
  maxHeight: "300px",
  overflowY: "scroll",
  top: "200px",
  width: "100%",
  zIndex: "999",
  right: "22%",
  "@media (max-width:750px)": {
    left: "8%",
  },
}));

export const MainBoxCompHeader = styled(Grid)(({ theme }) => ({
  position: "absolute",
  maxWidth: "762px",
  minHeight: "259px",
  maxHeight: "300px",
  overflowY: "scroll",
  top: "88px",
  width: "100%",
  zIndex: "999",
  // "@media (max-width: 600px)": {
  //   left: "5%",
  // },
}));

export const MainBoxCompRecent = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: "200px",
  width: "100%",
  zIndex: "999",
  // left: " 25rem",
  maxWidth: "1033px",
  minHeight: "259px",
}));

export const WrapBoxRecentSearch = styled(Box)(({ theme }) => ({
  minHeight: "259px",
  background: " #FFFFFF",
  boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.15);",
  borderRadius: " 0px 0px 8px 8px",
}));

export const WrapBoxComp = styled(Box)(({ theme }) => ({
  minHeight: "259px",
  overflow: "auto",
  background: " #FFFFFF",
  boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.15);",
  borderRadius: " 0px 0px 8px 8px",
}));

export const WrapGridComp = styled(Grid)(({ theme }) => ({
  padding: "10px 0px 0px 23px",
  justifyContent: "space-between",
  cursor: "pointer",
}));

export const TypoGraphyTextRecent = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "15px",
  color: "#58595B",
  opacity: "0.5",
  cursor: "pointer",
}));

export const TypoGraphyText = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "15px",
  marginBottom: "5px",
  color: "#58595B",
}));

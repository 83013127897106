import PropTypes from "prop-types";

export let SocialMediaPropTypes = PropTypes.shape({
  linkedinUrl: PropTypes.string,
  linkedinFollowers: PropTypes.number,
  facebookUrl: PropTypes.string,
  facebookFollowers: PropTypes.number,
  twitterUrl: PropTypes.string,
  twitterFollowers: PropTypes.string,
  instagramUrl: PropTypes.string,
  instagramFollowers: PropTypes.string
})

import React, { useState } from "react";
import { TextFieldStyled, HideStyled } from "./styled";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";

const TextFieldBox = ({
  name,
  placeholder,
  required = false,
  variant = "outlined",
  fullWidth,
  type,
  handleChange,
  value,
  error,
  disabled
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <>
      <TextFieldStyled
        variant={variant}
        required={required}
        fullWidth={fullWidth}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <HideStyled>Hide</HideStyled>
                ) : (
                  <HideStyled>Show</HideStyled>
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && error[name] && (
        <FormHelperText error={true}>{error[name]}</FormHelperText>
      )}
    </>
  );
};

export default TextFieldBox;

import {authenticated_api} from "./base";

export const getRoles = async (token) => {
    try {
      if (token) {
        return await authenticated_api.get(
          `/role/`,
          {headers: {Authorization: `Bearer ${token}`}});
      }
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };


export const updateRoleApi = async (token, roleId, roleName) => {
    try {
      if (token) {
        return await authenticated_api.put(`/role/`,
          {"role_id": roleId, "role_name": roleName},
          {headers: {Authorization: `Bearer ${token}`}});
      }
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
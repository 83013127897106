import {authenticated_api} from "./base";

export const getCompanyList = async (token, page, rowsPerPage) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/company/all/?page_size=${rowsPerPage}&page_num=${page}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompanyDetail = async (token, stagedId) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/company/?stagedId=${stagedId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateCompanyDetail = async (token, companyId, companydata) => {
  try {
    if (token) {
      return await authenticated_api.put(
        `/company/?company_id=${companyId}`,
        companydata,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteCompany = async (token, companyId) => {
  try {
    if (token) {
      return await authenticated_api.delete(
        `/company/?company_id=${companyId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const getCompanyDetailNew = async (token, id) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/company/?stagedId=${id}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const doesCompanyIdExist = async (token, stagedId) => {
  try {
    if (token) {
      return await authenticated_api.post(
        `/company/id/?staged_id=${stagedId}`,
        {},
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const registerCompanyInquiry = async (token, stagedId) => {
  try {
    if (token) {
      return await authenticated_api.post(
        `/company/inquiry/?staged_id=${stagedId}`,
        {},
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const getRecentlyViewedCompanies = async (token) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/company/viewed/`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
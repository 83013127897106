import {
    FacebookIconActive,
    FacebookIconInactive,
    InstagramIconActive,
    InstagramIconInactive,
    LinkedInIconActive,
    LinkedInIconInactive,
    TwitterIconActive,
    TwitterIconInactive
} from "./CustomIcons";
import {Box} from "@mui/material";

export const SocialMediaBar = (props) => {
    const {
        links = undefined,
        iconSize = "24px",
        justifyContent = 'flex-start'
    } = props;

    return (
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: justifyContent,
        }}
      >
          <a href={links?.linkedin ? links.linkedin : "#"} target="_blank" style={{lineHeight: 0}}>
              {links?.linkedin
                ?
                <LinkedInIconActive sx={{height: iconSize, width: iconSize}}/>
                :
                <LinkedInIconInactive sx={{height: iconSize, width: iconSize}}/>
              }
          </a>
          <a href={links?.facebook ? links.facebook : "#"} target="_blank" style={{lineHeight: 0}}>
              {links?.facebook
                ?
                <FacebookIconActive sx={{height: iconSize, width: iconSize}}/>
                :
                <FacebookIconInactive sx={{height: iconSize, width: iconSize}}/>
              }
          </a>
          <a href={links?.twitter ? links.twitter : "#"} target="_blank" style={{lineHeight: 0}}>
              {links?.twitter
                ?
                <TwitterIconActive sx={{height: iconSize, width: iconSize}}/>
                :
                <TwitterIconInactive sx={{height: iconSize, width: iconSize}}/>
              }
          </a>
          <a href={links?.instagram ? links.instagram : "#"} target="_blank" style={{lineHeight: 0}}>
              {links?.instagram
                ?
                <InstagramIconActive sx={{height: iconSize, width: iconSize}}/>
                :
                <InstagramIconInactive sx={{height: iconSize, width: iconSize}}/>
              }
          </a>
      </Box>
    )
}

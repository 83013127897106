
import PropTypes from "prop-types";
import {AddressPropTypes} from "./address";
import {SectorLevelPropTypes} from "./sector";
import {IncorporationPropTypes} from "./incorporation";
import {SocioEconomicPropTypes} from "./socioeconomic";
import {MetricPropTypes} from "./metric";
import {FranchisePropTypes} from "./franchise";
import {PersonPropTypes} from "./person";
import {SocialMediaPropTypes} from "./socialmedia";

export let CompanyPropTypes = PropTypes.shape({
  alphasearchId: PropTypes.string,
  alphasearchIdVariants: PropTypes.arrayOf[PropTypes.string],
  primaryName: PropTypes.string,
  alternativeNames: PropTypes.arrayOf(PropTypes.string),
  primaryLocation: AddressPropTypes,
  otherLocations: PropTypes.arrayOf(AddressPropTypes),
  primaryTelephone: PropTypes.string,
  alternateTelephones: PropTypes.arrayOf(PropTypes.string),
  sourcedClassifications: PropTypes.arrayOf(PropTypes.arrayOf(SectorLevelPropTypes)),               // Not quite right
  modeledClassifications: PropTypes.arrayOf(PropTypes.arrayOf(SectorLevelPropTypes)),               // Not quite right
  bestClassification: PropTypes.arrayOf(SectorLevelPropTypes),                                      // Not quite right
  descriptive: PropTypes.arrayOf(PropTypes.string),
  keywords: PropTypes.arrayOf(PropTypes.string),
  incorporation: IncorporationPropTypes,
  primaryLogo: PropTypes.string,
  alternateLogo: PropTypes.string,
  primaryWebsite: PropTypes.string,
  alternateWebsites: PropTypes.string,
  socioeconomic: SocioEconomicPropTypes,
  harmonizedHeadcount: MetricPropTypes,
  fteHeadcount: MetricPropTypes,
  totalHeadcount: MetricPropTypes,
  onlineHeadcount: MetricPropTypes,
  revenue: MetricPropTypes,
  revenuePerEmployee: MetricPropTypes,
  qualityScore: PropTypes.number,
  franchise: FranchisePropTypes,
  people: PropTypes.arrayOf(PersonPropTypes),
  social: PropTypes.arrayOf(SocialMediaPropTypes),
  similarCompanies: PropTypes.arrayOf(PropTypes.string),
  partition360: PropTypes.number
})
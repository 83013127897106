import React, { useState, useEffect } from "react";
import { Box, Modal, Grid, Divider } from "@mui/material";
import styled from "@emotion/styled";
import {
  CrossGrid,
  LinkTypography,
  MainTypography,
} from "../companyModalFile/CompanyStyled";
import CloseIcon from "@mui/icons-material/Close";
import StyledButton from "../StyledButton";
import ModalSideBar from "../companyModalFile/ModalSideBar";
import ModalSection from "../companyModalFile/ModalSection";
import defaultImageUrl from "../../assets/defaultImage.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BoxWraper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "809px",
  width: "100%",
  minHeight: "600px",
  maxHeight: "500px",
  overflow: "auto",
  background: "#FFFF",
  boxShadow: 24,
  padding: "32px",
  outline: "none",
  borderRadius: "10px",
  // maxWidth: "809px",
  // width: "100%",
  // padding: "32px",
  "@media (max-width: 600px)": {
    maxWidth: "600px",
    padding: "16px",
  },
}));

const CompanyDetails = ({
  companyDetailData,
  companyDetailsOpen,
  setCompanyDetailsOpen,
}) => {
  const { Company_Logo_URL, Company_Name, Website } = companyDetailData;
  const [isValid, setIsValid] = useState(null);

  const handleClose = () => {
    setCompanyDetailsOpen(false);
  };

  const handleClick = () => {
    setCompanyDetailsOpen(false);
  };

  const handleButtonLabel = () => {};

  const socialMediaGroup = [
    {
      label: "Facebook",
      image: "/Image/_Facebook.svg",
    },
    {
      label: "Twitter",
      image: "/Image/_Twitter.svg",
    },
    {
      label: "LinkedIn",
      image: "/Image/_Linkedin.svg",
    },
    {
      label: "Pinterest",
      image: "/Image/_Pinterest.svg",
    },
  ];
  const ButtonLabel = [
    {
      label: "Add to list",
      background: "#4A2CF5",
      textColor: "#fff",
      minWidth: "143px",
    },
    {
      label: "Search similar",
      background: "rgba(74,44,245,.3)",
      textColor: "#4A2CF5",
      minWidth: "160px",
    },
    {
      label: "Sync",
      background: "rgba(88, 89, 91,.16)",
      textColor: "#909497",
      minWidth: "117px",
    },
  ];

  const checkImageValidity = () => {
    const img = new Image();
    img.src = Company_Logo_URL;

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };
  useEffect(() => {
    checkImageValidity();
  }, [Company_Logo_URL]);

  return (
    <Modal
      open={companyDetailsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxWraper>
        <Grid container justifyContent={"space-between"} flexWrap={"initial"}>
          <Grid item>
            <Grid container gap={"13px"} alignItems={"center"}>
              <Grid item>
                <img
                  src={isValid ? Company_Logo_URL : defaultImageUrl}
                  height="74px"
                  width="74px"
                  alt="logo"
                  style={{
                    borderRadius: "12px",
                  }}
                />
              </Grid>
              <Grid item>
                <Grid container flexDirection={"column"} gap={"16px"}>
                  <Grid item>
                    <MainTypography>{Company_Name}</MainTypography>
                  </Grid>
                  <Grid item>
                    <Grid container columnGap={"26px"}>
                      <Grid item>
                        <LinkTypography
                          href={`http://${Website}`}
                          target="_blank"
                          color="#00a3d0"
                          fontWeight={700}
                        >
                          {Website}
                        </LinkTypography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"10px"}
                        >
                          {socialMediaGroup?.map((item) => {
                            const { label, image } = item;
                            return (
                              <Grid item>
                                <img
                                  src={image}
                                  width={"20px"}
                                  height={"20px"}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CrossGrid item onClick={() => handleClick()}>
            <CloseIcon fontSize="14px" />
          </CrossGrid>
        </Grid>
        {/* <Grid container mt={"30px"} gap={"14px"}>
          {ButtonLabel?.map((item, key) => {
            return (
              <StyledButton
                key={key}
                label={item.label}
                backgroundColor={item.background}
                textColor={item.textColor}
                handleButtonLabel={handleButtonLabel}
                padding={"17px"}
                minWidth={item.minWidth}
              />
            );
          })}
        </Grid> */}

        <Divider sx={{ margin: "28px 0 30px 0" }} />
        <Grid container>
          <ModalSideBar companyDetail={companyDetailData} />
        </Grid>
        <Divider sx={{ marginBottom: "28px" }} />
        <Grid container>
          <ModalSection
            companyDetail={companyDetailData}
            companyDetailsOpen={companyDetailsOpen}
          />
        </Grid>
      </BoxWraper>
    </Modal>
  );
};

export default CompanyDetails;

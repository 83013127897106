import {authenticated_api} from "./base";

export const getTargetLists = async (token) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/list/all/`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getTargetList = async (token, listId) => {
  try {
    if (token) {
      return await authenticated_api.get(
        `/list/?list_id=${listId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateTargetListDescriptors = async (token, listId, newListName, newListDescription) => {
  const body = {
    list_id: listId,
    new_list_name: newListName,
    new_description: newListDescription
  }

  try {
    if (token) {
      return await authenticated_api.post(
        `/list/`,
        body,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const createNewTargetList = async (token, listName, listDescription) => {
  const body = {
    list_name: listName,
    description: listDescription
  }

  try {
    if (token) {
      return await authenticated_api.put(
        `/list/`,
        body,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTargetList = async (token, listId) => {
  try {
    if (token) {
      return await authenticated_api.delete(
        `/list/?list_id=${listId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addCompanyToList = async (token, listId, stagedId, note) => {
  const body = {
    list_id: listId,
    staged_id: stagedId,
    note: note
  }

  try {
    if (token) {
      return await authenticated_api.put(
        `/list/item`,
        body,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeCompanyFromList = async (token, listId, stagedId) => {
  try {
    if (token) {
      return await authenticated_api.delete(
        `/list/item/?list_id=${listId}&staged_id=${stagedId}`,
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const isListNameAvailable = async (token, listName) => {
  try {
    if (token) {
      return await authenticated_api.post(
        `/list/name/?name=${listName}`,
        {},
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const listMembershipForCompany = async (token, stagedId) => {
  try {
    if (token) {
      return await authenticated_api.post(
        `/list/company/`,
        {stagedId: stagedId},
        {headers: {Authorization: `Bearer ${token}`}});
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
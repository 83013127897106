import styled from "@emotion/styled";
import {Box, Card, Typography, useTheme} from "@mui/material";

const CardBoundary = styled(Card)(({ theme }) => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  backgroundColor: theme.palette.widget.inactive,
  borderRadius: "8px",
  width: "500px",
  padding: "15px",
  transition: "transform 0.15s ease-in-out",
}));


const NoInfoCard = (props) => {
  return (
    <CardBoundary variant="outlined">
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle2">
          There is no information for this category
        </Typography>
      </Box>
    </CardBoundary>
  )
}

export default NoInfoCard
import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PublicRoute = () => {
  let userid = localStorage.getItem("authToken") == null ? false : true;

  return <>{!userid ? <Outlet /> : <Navigate to="/landing" />};</>;
};

export default PublicRoute;

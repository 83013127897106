import CompanyListCard from "./Common/CompanyListCard";


const RecentlyViewedCompanyCards = (props) => {
  const {companyIds} = props

  return (
    <>
      {companyIds.map(cId => {
        return(<CompanyListCard id={cId}/>)
      })}
    </>
  )
}

export default RecentlyViewedCompanyCards
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./container/Login";
import Landing from "./container/Landing";
import NotFound from "./components/layouts/NotFound/Error";
import CreateAdmin from "./container/Admin/createAdmin/CreateAdmin";
import SendInvite from "./container/Admin/invite/SendInvite";
import CompanyData from "./container/Admin/manageCompany/Companies";
import UserData from "./container/Admin/manageUser/UserList";
import CompanyList from "./container/Search/CompanyList";
import { ForgotPassword } from "./container/Password/ForgotPassword";
import ResetPassword from "./container/Password/ResetPassword";
import Signup from "./container/SignupPage";
import PrivateRoutes from "./routes/PrivateRoute";
import UpdatePasswordPage from "./container/User/UpdatePassword";
import PublicRoute from "./routes/PublicRoute";
import TargetLists from "./container/TargetLists/TargetLists";
import {CompanyDetailPage} from "./container/Company/CompanyDetailPage";


function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route exact element={<PublicRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/page" element={<NotFound />} />
          <Route path="/password/forgot" element={<ForgotPassword />} />
          <Route path="/password/reset/:authCode" element={<ResetPassword />} />
          <Route path="/signup/:inviteCode" element={<Signup />} />
        </Route>

        <Route exact element={<PrivateRoutes />}>
          <Route path="/landing" element={<Landing />} />
          <Route path="/change-password" element={<UpdatePasswordPage />} />
          <Route path="/lists/companies/:searchKey?" element={<CompanyList />} />
          <Route path="/lists/targets/" element={<TargetLists />} />
          <Route path="/company/:stagedId?" element={<CompanyDetailPage />} />
          <Route path="/admin/send-invite" element={<SendInvite />} />
          <Route path="/admin/create-admin" element={<CreateAdmin />} />
          <Route path="/admin/companies/data" element={<CompanyData />} />
          <Route path="/admin/user/data" element={<UserData />} />
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default App;

import {Chip} from "@mui/material";
import {Business} from "@mui/icons-material";
import * as React from "react";

export const HqChip = () =>
  <Chip
    label="HQ"
    size="small"
    icon={<Business/>}
    color={"primary"}
    sx={{pr: "3px", pl: "3px", ml: "15px"}}
  />
import styled from "@emotion/styled";
import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import StyledButton from "../StyledButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import PlaceIcon from '@mui/icons-material/Place';
import ContactsIcon from '@mui/icons-material/Contacts';
import {
  DescriptionTypography,
  SubDescriptionTypography,
  StyledChip
} from "./CompanyStyled";

export const BoxWraper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "0.5px solid #D7DBDD",
}));

const labelGroup = [
  {
    label: "Industry",
    key: "Industry",
  },
  {
    label: "Ownership Status",
    key: "Ownership Status",
  },
  {
    label: "Email",
    key: "Email",
  },
  {
    label: "Phone Number",
    key: "Phone",
  },
  {
    label: "Headquarters",
    key: "Headquarters",
  },
  {
    label: "Executives",
    key: "Executives",
  },
];

const ModalSideBar = ({ companyDetail }) => {
  return (
    <>
      {labelGroup?.map((item, index) => {
        const { label, key } = item;
        return (
          <>
            <Grid container justifyContent={"space-between"} mb={"32px"}>
              <Grid item>
                <DescriptionTypography>{label}</DescriptionTypography>
              </Grid>
              <Grid item>
                {index === 1 ?
                <StyledChip 
                avatar={<PlaceIcon style={{width:"11px",height:"13px",color:"#25803E"}} />}
                label="Orlando, FL"
                variant="outlined"
                />
                  : index === 5 ? <Grid style={{display:"flex",gap:"5px"}}>
                     <Grid >
                      <ContactsIcon  style={{width:"15px",height:"15px",color:"#4A2CF5"}} />
                      </Grid>
                      <Grid>
                        <SubDescriptionTypography style={{color:"#4A2CF5"}}>John Caules</SubDescriptionTypography>
                        </Grid>
                    </Grid> 
                  :
                  <SubDescriptionTypography>
                    {companyDetail?.[key] || key}
                  </SubDescriptionTypography>
                  }
              </Grid>
            </Grid>
          </>
        );
      })}
      {/* <Grid sx={{ padding: "10px", width: "100%" }}>
        <BoxWraper>
          <p>Industry</p>
          <StyledButton label={companyDetail.Industry} textColor="#909497" />
        </BoxWraper>
        <BoxWraper>
          <p>Ownership Status</p>
          <StyledButton label={"Investor Backed"} textColor="#909497" />
        </BoxWraper>
        <BoxWraper>
          <p>Email</p>
          <p>xyz@email.com</p>
        </BoxWraper>
        <BoxWraper>
          <p>Social</p>
          <Box display={"flex"} gap={3} color="#00a3d0">
            <LinkedInIcon />
            <TwitterIcon />
            <FacebookIcon />
            <PinterestIcon />
          </Box>
        </BoxWraper>
        <BoxWraper>
          <p>Phone</p>
          <p>{companyDetail.Phone}</p>
        </BoxWraper>
        <BoxWraper>
          <p>Headquarters</p>
          <p>{companyDetail.Headquarters}</p>
        </BoxWraper>
        <BoxWraper>
          <p>Executives</p>
          <Link href="#" underline="hover" color="#00a3d0">
            John Caules
          </Link>
        </BoxWraper>
        <Box>
          <Typography variant="h5" fontWeight={600} sx={{ mt: 4, mb: 3 }}>
            Keywords
          </Typography>
          <Grid sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {keywordLabel?.map((item, key) => {
              return (
                <StyledButton key={key} label={item} textColor="#909497" />
              );
            })}
          </Grid>
        </Box>
      </Grid> */}
    </>
  );
};

export default ModalSideBar;

import React, { useEffect, useState } from "react";
import { TypoGraphyText, WrapGridComp } from "./LandingSubHeaderStyled";
import { Grid } from "@mui/material";
import defaultImageUrl from "../../assets/defaultImage.svg";
import { Link } from "react-router-dom";
import ScheduleIcon from "@mui/icons-material/Schedule";
export default function RecentViewComp({
  recentViewData,
  handleCompayDetails,
}) {
  const { Company_Logo_URL, Company_Name, Website, Company_ID } =
    recentViewData;
  const [isValid, setIsValid] = useState(null);
  const checkImageValidity = () => {
    const img = new Image();
    img.src = Company_Logo_URL;

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };
  useEffect(() => {
    checkImageValidity();
  }, [Company_Logo_URL]);
  return (
    <WrapGridComp container>
      <Grid
        onClick={() => handleCompayDetails(Company_ID)}
        sx={{ display: "flex" }}
      >
        <img
          src={isValid ? Company_Logo_URL : defaultImageUrl}
          style={{
            width: 36,
            height: 35,
            borderRadius: "12px",
          }}
        />
        <Grid sx={{ marginLeft: "15px" }}>
          <TypoGraphyText>{Company_Name}</TypoGraphyText>
          <Link
            href={`http://${Website}`}
            target="_blank"
            underline="none"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
              textDecoration: "underline",
              color: "#4764F6",
              marginBottom: "29px",
            }}
          >
            {Website}
          </Link>
        </Grid>
      </Grid>
      <Grid sx={{ marginRight: "37.5px" }} item>
        <ScheduleIcon style={{ fill: "rgb(88, 89, 91,0.3)" }} />
      </Grid>
    </WrapGridComp>
  );
}

import { Button } from "@mui/material";
import React from "react";

const StyledButton = ({
  label,
  type,
  backgroundColor,
  textColor,
  fWidth,
  handleButtonLabel,
  mt,
  padding,
  minWidth,
}) => {
  return (
    <Button
      type={type}
      maxWidth={fWidth}
      width={"100%"}
      variant="outlined"
      style={{
        borderColor: "#D7DBDD",
        fontStyle:"normal",
        fontWeight: 600,
        fontSize:"16px",
        textTransform: "none",
        background: `${backgroundColor}`,
        color: `${textColor}`,
        marginTop: `${mt}`,
        padding,
        borderRadius: "6px",
        minWidth
      }}
      onClick={handleButtonLabel}
    >
      {label}
    </Button>
  );
};

export default StyledButton;

import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const MainStyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "29px",
  color: theme.palette.mainPrimary.prior,
  textTransform: "initial",
  lineHeight: "37px",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "29px",
  color: theme.palette.mainSecondary.prior,
  textTransform: "initial",
  lineHeight: "37px",
}));

export const ItemGrid = styled(Grid)`
  &::before {
    content: "";
    position: absolute;
    background: url("/Image/subheader.svg");
    width: 128px;
    height: 157px;
    left: 0;
  },
  &::after {
    content: "";
    position: absolute;
    background: url("/Image/sub-header-right-img.png") no-repeat;
    width: 230px;
    height: 157px;
    right: 0px;
    top: 0;
  }
`;

import styled from "@emotion/styled";
import { Grid, Link, Typography,Chip } from "@mui/material";

export const CrossGrid = styled(Grid)`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #292d32;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MainTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`;

export const LinkTypography = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  leading-trim: both;
  text-edge: cap;
  text-decoration-line: underline;
  color: #4764f6;
`;

export const DescriptionTypography = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const SubDescriptionTypography = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #58595b;
`;

export const SizingLabelTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const SizingKeyTypography = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  leading-trim: both;
  text-edge: cap;

  color: #58595b;
`;

export const KeyTypographyStyled = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`;

export const MainTitle = styled(Grid)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const SubMainTitle = styled(Grid)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  leading-trim: both;
  text-edge: cap;
  color: rgba(88, 89, 91, 0.8);
`;

export const PrimaryTextGrid = styled(Grid)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`;

export const PrimarySubTextGrid = styled(Grid)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  leading-trim: both;
  text-edge: cap;
  color: #58595b;
`;

export const ConferenceStyleGrid = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color:rgba(0, 0, 0, 0.5)  ;
`;

export const  StyledChip  = styled(Chip)`
background: #E6FFED;
border-radius: 50px;
max-width: 153px;
max-height: 30px;
color: "#25803E" !important;
font-weight: 500;
font-size: 16px;
line-height: 20px;
`;

import {createTheme} from "@mui/material/styles";

export const alphaSearchTheme = createTheme({
  palette: {
    primary: {
      main: "#4A2CF5",
      light: "#775EFF",
      dark: "#3B23C6",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#F6F4FF",
    },
    tertiary: {
      main: "#7141ff"
    },
    mainPrimary: {
      prior: "#4A2CF5",
    },

    mainSecondary: {
      prior: "#000000",
    },
    altPalette: {
      main: "#170f4a",
      light: "#a0138e",
      dark: "#07021a",
      contrastText: "#FFFFFF"
    },
    widget: {
      dark: "#58595B",
      light: "#bdbdbd",
      main: "#6D6968",
      contrastText: "#ffffff",
      inactive: "#eeeeee"
    },
    motifs: {
      money: {  // hue 117
        main: "#36f52c", // saturation 82, value 96
        light: "#def5dc",  // saturation 10
        dark: "#218518",  // value 52
        contrastText: "#FFFFFF"
      },
      headcount: {  // hue 29
        main: "#E7AB73",  // saturation 100, value 100
        light: "#FFF2E6",
        dark: "#CE6300",  // value 81
        contrastText: "#FFFFFF"
      },
    },
  },
  typography: {
    fontFamily: ["Plus Jakarta Sans", "sans-serif"].join(","),
    // fontSize: 16,
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
      lineHeight: 1.2,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h4: {
      fontWeight: 800,
      fontSize: "22px",
      color: "#000000",
      lineHeight: "28px",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "23px",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "-0.005px",
    },
    mainText: {
      color: "#000000",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "30px",
      lineHeight: "38px",
    },
    mainSubText : {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize:"18px",
      color:"#8692A6"
    },
    h6: {
      fontStyle:"normal",
      fontWeight: 600,
      fontSize:"16px",
      lineHeight:"20px",
      color:"#58595B"
    },
    buttonFace : {
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "initial",
    },
    formValidation : {
      color: "red",
      fontWeight: 600,
      fontSize: "16px",
    },
    toolTipText: {
      fontWeight: 700,
      fontSize: 16,
    }
  },
  shape: {
    borderRadius: 6
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      cdSm: 650,
      cdMd: 1240,
      cdLg: 1785,
      cdXl: 2200,
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  overrides: {},
});
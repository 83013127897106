import ForestIcon from '@mui/icons-material/Forest';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ConstructionIcon from '@mui/icons-material/Construction';
import FactoryIcon from '@mui/icons-material/Factory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TerminalIcon from '@mui/icons-material/Terminal';
import SavingsIcon from '@mui/icons-material/Savings';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WorkIcon from '@mui/icons-material/Work';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SchoolIcon from '@mui/icons-material/School';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const icons = new Map ([
  [11, <ForestIcon/>],
  [21, <OilBarrelIcon/>],
  [22, <ElectricalServicesIcon/>],
  [23, <ConstructionIcon/>],
  [31, <FactoryIcon/>],
  [32, <FactoryIcon/>],
  [33, <FactoryIcon/>],
  [42, <WarehouseIcon/>],
  [44, <StorefrontIcon/>],
  [45, <StorefrontIcon/>],
  [48, <LocalShippingIcon/>],
  [49, <LocalShippingIcon/>],
  [51, <TerminalIcon/>],
  [52, <SavingsIcon/>],
  [53, <MapsHomeWorkIcon/>],
  [54, <EngineeringIcon/>],
  [55, <WorkIcon/>],
  [56, <ManageAccountsIcon/>],
  [61, <SchoolIcon/>],
  [62, <LocalHospitalIcon/>],
  [71, <ConfirmationNumberIcon/>],
  [72, <FoodBankIcon/>],
  [81, <BusinessIcon/>],
  [92, <AccountBalanceIcon/>]
]);

function getLeftTwoDigits(number) {
    let numberStr = number.toString();
    if (numberStr.length < 2) {
        return undefined;
    }

    return parseInt(numberStr.slice(0, 2));
}

export const iconForNAICS = (code) => {
  let l2 = getLeftTwoDigits(code)
  if (icons.has(l2)) {
    return icons.get(l2)
  } else {
    return <BusinessIcon/>
  }
}